import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PublicService {
    baseUrl: string = environment.api;

    constructor(private httpClient: HttpClient) { }

    get_actualizaciones(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/public/actualizaciones?offset=0&limit=3&search=user')
            .pipe(map((data: any) => data));
      }

    post_cambiar_password(request: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/auth/change-password', request)
            .pipe(
                catchError(this.handleError)
            );
    }

    get_perfil_lawyers(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/perfilawyers/profile')
            .pipe(map((data: any) => data));
    }

    get_wall(lotes, practicas): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/wall-partner' + '?lote=' + lotes + '&practicas=' + practicas)
            .pipe(map((data: any) => data));
    }

    post_forgot_password(request: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/auth/forgot-password', request)
            .pipe(
                catchError(this.handleError)
            );
    }

    get_cargar_tipodecaso(apertura): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/public/tiposdecasos/'+apertura)
            .pipe(map((data: any) => data));
    }

    post_abrir_consulta(request: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/private/abrirconsulta', request)
            .pipe(
                catchError(this.handleError)
            );
    }

    post_mi_consulta(request: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/private/misconsultas', request)
            .pipe(
                catchError(this.handleError)
            );
    }

    get_profile_lawyers(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/perfilawyers?offset=0&limit=10')
            .pipe(map((data: any) => data));
    }

    get_tendencias(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/public/tiposdecasos/tendencias?offset=0&limit=10')
            .pipe(map((data: any) => data));
    }

    get_tipos_casos(detalle): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/public/tiposdecasos/' + detalle)
            .pipe(map((data: any) => data));
    }

    get_perfil_lawyer(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/perfilawyers/profile')
            .pipe(map((data: any) => data));
    }

    get_comentarios_by_id(id): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/comentarios?comentariosId='+id)
            .pipe(map((data: any) => data));
    }

    post_agregar_comentarios(request: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/private/comentarios', request)
            .pipe(
                catchError(this.handleError)
            );
    }

    get_search_consulta(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/casoslawyers?search=consulta')  
        .pipe(map((data: any) => data));
    }

    get_chats_full(id): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/chats/full/'+id)  
        .pipe(map((data: any) => data));
    }

    get_url_expediente(room): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/expedientes/detail/'+room)  
        .pipe(map((data: any) => data));
    }

    post_url_expediente_detail(request: any): Observable<any> {
        return this.httpClient.post<any>(this.baseUrl + '/private/expedientes', request)
            .pipe(
                catchError(this.handleError)
            );
    }

    get_chat(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/chats/partners')  
        .pipe(map((data: any) => data));
    }

    patch_profile(idUsuario, request): Observable<any> {
        return this.httpClient.patch<any>(this.baseUrl + '/private/perfilawyers'+idUsuario, request)
            .pipe(
                catchError(this.handleError)
            );
    }

    get_casoslawyer(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/casoslawyers?search=productos')  
        .pipe(map((data: any) => data));
    }

    get_reclamaciones(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/casoslawyers?search=reclamaciones_indemnizaciones')  
        .pipe(map((data: any) => data));
    }'/private/chats/meet'

    get_meet(): Observable<any> {
        return this.httpClient.get(this.baseUrl + '/private/chats/meet')  
        .pipe(map((data: any) => data));
    }

    delete_metodos_pago(id): Observable<any> {
        return this.httpClient.delete(this.baseUrl + '/private/metodosdepagos/' + id)
            .pipe(map((data: any) => data));
    }

    refresh_token(request): Observable<any> {
        return this.httpClient.post(this.baseUrl + '/auth/refresh', request)
            .pipe(
                catchError(this.handleError)
            );
    }

    handleError(error: any) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
            return errorMessage;
        });
    }
}
