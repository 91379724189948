import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndexRoutingModule } from './index-routing.module';
import { IndexComponent } from './index.component';
import { ChatsComponent } from '../tabs/chats/chats.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    IndexRoutingModule
  ],
  bootstrap: [IndexComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class IndexModule { }
