import { Component, ComponentFactoryResolver, ContentChildren, HostListener, OnInit, ViewChild, ViewContainerRef,  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Message } from './chat.model';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { DomSanitizer } from '@angular/platform-browser'
import { ChatsComponent } from '../tabs/chats/chats.component';
import { ProductosComponent } from '../tabs/productos/productos.component';
import { HomeComponent } from '../tabs/home/home.component';
import { WallComponent } from '../tabs/abrirconsulta/wall.component';
import { AsuntosComponent } from '../tabs/asuntos/asuntos.component';
import { ReclamacionesIndemnizacionesComponent } from '../tabs/reclamaciones-indemnizaciones/reclamaciones-indemnizaciones.component';
import { PerfilComponent } from '../tabs/perfil/perfil.component';
import { ChannelsComponent } from '../tabs/channels/channels.component';
import { InvestigacionComponent } from '../tabs/investigacion/investigacion.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

/**
 * Chat-component
 */
export class IndexComponent implements OnInit  {

  activetab = 1;
  Messages: Message[];

  lang: string;
  public room;
  private _ref;
  private _ref1;
  public bandera = false;

  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  @ViewChild('consultas', { read: ViewContainerRef }) consultas: ViewContainerRef;
  @ViewChild('home', { read: ViewContainerRef }) home: ViewContainerRef;

  constructor(public authenticationService: AuthenticationService, private authFackservice: AuthfakeauthenticationService, private componentFactoryResolver: ComponentFactoryResolver, private authService: AuthenticationService,
    private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private sanitizer: DomSanitizer, private factoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {


    console.log(this.room);
    var baseUrl = window.location.host === 'localhost:4201' ? 'http://' + window.location.host + '/' : 'https://brancheslegal-user.web.app/';

    setTimeout(() => {
      const fac = this.factoryResolver.resolveComponentFactory(HomeComponent);
      this._ref1 = this.home.createComponent(fac);
      const factory = this.factoryResolver.resolveComponentFactory(WallComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    }, 1000);
  }


  activeTab(n) {
    this.activetab = n;
    if (n === 1) {
      const factory = this.factoryResolver.resolveComponentFactory(WallComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    } else if (n === 2) {
      const factory = this.factoryResolver.resolveComponentFactory(AsuntosComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    } else if (n === 3) {
      const factory = this.factoryResolver.resolveComponentFactory(ProductosComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    } else if (n === 4) {
      const factory = this.factoryResolver.resolveComponentFactory(ReclamacionesIndemnizacionesComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    } else if (n === 5) {
      const factory = this.factoryResolver.resolveComponentFactory(InvestigacionComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    } else if (n === 6) {
      const factory = this.factoryResolver.resolveComponentFactory(ChatsComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    } else if (n === 7) {
      const factory = this.factoryResolver.resolveComponentFactory(ChannelsComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    }  else if (n === 8) {
      const factory = this.factoryResolver.resolveComponentFactory(PerfilComponent);
      this._ref = this.consultas.createComponent(factory);
      this._ref.instance.practicaId = 0;
      this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
      });
    } else {

    }
  }

  removeTab() {
    this.consultas.clear();
    console.log(this.consultas);
  }

  /**
   * Show user profile
   */
  // tslint:disable-next-line: typedef
  showUserProfile() {
    document.getElementById('profile-detail').style.display = 'block';
  }

  /**
   * Close user chat
   */
  // tslint:disable-next-line: typedef
  closeUserChat() {
    document.getElementById('chat-room').classList.remove('user-chat-show');
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else if (environment.defaultauth === 'fackbackend') {
      this.authenticationService.logout();
      this.authFackservice.logout();
      this.deleteAllCookies();
      localStorage.clear();
      sessionStorage.clear();
    }
    this.router.navigate(['/account/login']);
  }
  //Perfil usuario Editar Informacion
  perfilAvatar() {
    this.router.navigate(['/account/perfil']);
  }

  deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  openCallModal(content) {
    this.modalService.open(content, { centered: true });
  }


  openVideoModal(content) {
    this.modalService.open(content, { centered: true });
  }
  
  @HostListener("window:scroll", ['$event'])
  parentOnScroll($event:any){
    
    let scrollOffset = $event.srcElement.scrollTop;
    var url = (<HTMLInputElement>document.getElementById('id1')).value;
    if (scrollOffset > 300) {
      document.getElementById("header").style.height = "40px";
      document.getElementById("header-contenido").style.display = "none";
      document.getElementById("header").style.background = "linear-gradient(rgba(0, 0, 0, 0.7),  rgba(0, 0, 0, 0.7)), url(" + url + ") no-repeat center center / cover";
    } else {
      document.getElementById("header").style.height = "215px";
      document.getElementById("header-contenido").style.display = "inherit";
      document.getElementById("header").style.background = "linear-gradient(rgba(0, 0, 0, 0.3),  rgba(0, 0, 0, 0.3)), url(" + url + ") no-repeat center center / cover";
    }
  }

}