import { ThemeChatComponent } from './../../themechat/themechat.component';
import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver  } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-room',
    templateUrl: './room.component.html',
    styleUrls: ['./room.component.scss']
  })
  export class RoomComponent implements OnInit {
    @Output () abrir: EventEmitter<string> = new EventEmitter();
    @Output () cerrar: EventEmitter<string> = new EventEmitter();
    listPracticas: any;
    practicaTitulo: string;
    @Input () practicaId: string;
    @Input () tabId: string;
    private _ref;
    activo = '1';
    seccion = 'home';
    @Output () room: EventEmitter<string> = new EventEmitter();
    ancho = window.innerWidth;
    alto = window.innerHeight;
    error;
    colorToggle = 'primary';
    backgroundColorToggle = 'primary';
    number = 134;
    urlChat = "https://central-serviceslb.herokuapp.com/private/chats/full/";
    @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
    @ViewChild('roomChat', { read: ViewContainerRef }) roomChat: ViewContainerRef;
    loadChat = false;



  constructor(private factoryResolver: ComponentFactoryResolver, private http: HttpClient) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    console.log(this.practicaId);
    setTimeout(()=> {            
      this.cargarChat(this.practicaId);
    }, 1000);
    if (this.practicaId === 'penal') {
      this.practicaTitulo = 'Penal';
      this.listPracticas = [];
    } else if (this.practicaId === 'seguros') {
   this.listPracticas = [];
    } else {
      this.listPracticas = [];
    }
  }


  cargarChat(id): void {
    console.log(id);
    const factory = this.factoryResolver.resolveComponentFactory(ThemeChatComponent);
    this._ref = this.roomChat.createComponent(factory);
    this._ref.instance.id = id;

    this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
    });
  }

  abrirPractica(id): void {
    this.seccion = 'inicio';
  }

  cerrarPractica(): void {
    this.cerrar.emit('true');
  }

}