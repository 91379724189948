<div *ngIf="seccion === 'home'">
  <div id="header">
    <textarea id="id1" style="display: none">https://esug.mx/legal/fiscal.jpg</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarPractica()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left"
                width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <path
                    d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                  <path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                  <path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                </g>
              </svg></span>
            <a href="javascript:;">
              <svg class="lnr-cog" fill="red" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                viewBox="0 0 24 24">
                <path
                  d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
              </svg>
            </a>
          </div>
          <div id="header-contenido">
            <h1 class="heading">Tus asuntos</h1>
            <div class="mynav01">
              <div class="lnr-chevron-left01">{{listAsuntos.length}}<br>Asuntos actualmente</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Start profile content -->
  <div class="p-3" id="contenedor-main">
    <div class="tipos"><br>ASUNTOS<br></div>
    <h5>ACTUALES</h5>

    <app-scheleton *ngIf="load"></app-scheleton>
    <div *ngIf="!load">
    <section *ngFor="let item of listAsuntos" class="mx-auto my-3" style="max-width: 23rem;" (click)="abrirPractica(item.consulta)">

        <div class="col-xs-12 col-sm-12222222">
          <div class="card" style="background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2)), url('https://esug.mx/legal/robo.jpg');">
            <div class="card-description">
             <div class="tag1"><a href="javascript:;">
                <svg class="lnr-cog" fill="red" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
              </a></div>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-unstyled list-inline mb-0">
              <li class="list-inline-item">
                <span class="text-muted card-titulo">{{item.informacion_apertura.motivo}}</span><br>
                  <span class="day">{{item.createdAt | date: 'dd/MM/yyyy hh:mm'}}</span><br>
                  <span class="text-muted card-subtitulo">Practica: {{item.resena}} </span><br>
                  <span class="text-muted card-subtitulo">{{item.precio}}$ </span><span class="card-subtitulo1">en total por la consulta</span><br>
                  <span class="text-muted card-subtitulo3">Ver detalle de asesoría </span>
                <svg fill="black" class="lnr-chevron-left" width="10" height="18" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 511.996 511.996" style="enable-background:new 0 0 511.996 511.996;" xml:space="preserve">
             <g>
               <g>
                 <path d="M508.245,246.953L363.435,102.133c-5.001-5.001-13.099-5.001-18.099,0c-5.001,5-5.001,13.099,0,18.099l122.965,122.965
                   H12.8c-7.074,0-12.8,5.726-12.8,12.8c0,7.074,5.726,12.8,12.8,12.8h455.492L345.327,391.763c-5.001,5-5.001,13.099,0,18.099
                   c5.009,5.001,13.099,5.001,18.108,0l144.811-144.811C513.246,260.051,513.246,251.953,508.245,246.953z"/>
               </g>
             </g>
             </svg>           
              </li>
            </ul>
          </div>
        </div>
    </section>
    </div>
    </div>
  </div>

<ng-container #practica></ng-container>