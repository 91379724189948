import { DialogData } from './../apertura/apertura.component';
import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver, AfterViewInit  } from '@angular/core';
import { DetalleComponent } from './../detalle/detalle.component';
import { HttpClient } from '@angular/common/http'; // <----
import { environment } from '../../../../../../src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-practica',
  templateUrl: './practica.component.html',
  styleUrls: ['./practica.component.scss']
})
/**
 * Tabs-Profile component
 */
export class PracticaComponent implements OnInit, AfterViewInit {
    @Output () abrir: EventEmitter<string> = new EventEmitter();
    @Output () cerrar: EventEmitter<string> = new EventEmitter();
    listComentarios: any;
    practicaTitulo: string;
    @Input () detalleCaso: any;
    private _ref;
    seccion = 'home';
    number = 134;
    urlComentarios: string = environment.api + '/private/comentarios?comentariosId=';
    urlAgregarComentarios: string = environment.api + '/private/comentarios';
    error;
    url: string = environment.api + '/private/perfilawyers/profile';
    @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
    desc1 = '123';
    partner: any;
    checkList = [];
    info_conf = [];
    public formGroup: FormGroup;

  constructor(private publicService: PublicService, private factoryResolver: ComponentFactoryResolver, private http: HttpClient, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.buildForm();
    this.cargarComentarios();
    this.cargarPartner();
    console.log(this.detalleCaso);
    let obj = Object.keys(this.detalleCaso.informacion_apertura.checkbox_list);
    console.log(obj);
    for(let i=0; i< obj.length; i++) {
      let clave = obj[i];
      console.log(this.detalleCaso.informacion_apertura.checkbox_list[clave]);
      if (this.detalleCaso.informacion_apertura.checkbox_list[clave]) {
        this.checkList.push({value: clave.replace(/_+/g, ' ') + ' Si'});
      }
    }

    let objC = Object.keys(this.detalleCaso.informacion_apertura);
    for(let i=0; i< objC.length; i++) {
      let clave = objC[i];
      console.log(this.detalleCaso.informacion_apertura[clave]);
      if (clave !== 'descripcion_general' && clave !== 'motivo' && clave !== 'checkbox_list') {
        let quitaCr = clave.replace(/_+/g, ' ');
        this.info_conf.push({titulo: quitaCr.charAt(0).toUpperCase() + quitaCr.slice(1), contenido: this.detalleCaso.informacion_apertura[clave]});
      }
    }
    console.log(this.checkList);
    console.log(this.info_conf);
    console.log(this.detalleCaso);
  }

  ngAfterViewInit()	{

  }

  cargarPartner(): void {
    this.publicService.get_perfil_lawyer().subscribe(data => {
      console.log(data);
      this.partner = data;
     },error => this.error = error);
  }

  cargarComentarios(): void {
    this.publicService.get_comentarios_by_id(this.detalleCaso.id).subscribe(data => {
        console.log(data);
        this.listComentarios = data.items;
     },error => this.error = error);
  }

  abrirPractica(id): void {
    this.seccion = 'inicio';

    console.log(id);

    const factory = this.factoryResolver.resolveComponentFactory(DetalleComponent);
    this._ref = this.detalle.createComponent(factory);

    this._ref.instance.detalleId = id;
    this._ref.instance.cerrarDetalle.subscribe(data => {
        console.log(data);
        this.detalle.clear();
        this.seccion = 'home';
    });
  }

  proceder(): void {
    let fechaActual = new Date()
    let request = {
      "fecha": fechaActual.toISOString().split('T')[0] + " 00:00:00",
      "idConsulta": this.detalleCaso.id,
      "comentario": this.formGroup.value.comentarios,
      "lawyer": this.partner.id
    };
    this.publicService.post_agregar_comentarios(request).subscribe(response => {
      console.log(response);
      window.location.reload();
    }, error => console.log(error));
    console.log(request);

  }

  private buildForm(){
    this.formGroup = this.formBuilder.group({
      comentarios: ['', [
        Validators.required,
        Validators.minLength(500)
      ]]
    });
  }

  updateObj() {
    this.desc1 = 'dsdsdsd';
  }

  cerrarPractica(): void {
    this.cerrar.emit('true');
  }

}
