import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '../account/auth/login/login.component';
import { AuthRedirectComponent } from '../auth-redirect/auth-redirect.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    { path: 'inicio', loadChildren: () => import('./index/index.module').then(m => m.IndexModule) },
    { path: 'abrir-consulta/:idPractica', loadChildren: () => import('./tabs/abrirconsulta/wall.module').then(m => m.WallModule) },
    { path: 'chats', loadChildren: () => import('./tabs/chats/chats.module').then(m => m.ChatsModule) },
    { path: 'practica', loadChildren: () => import('./tabs/abrirconsulta/practica/practica.module').then(m => m.PracticaModule) },
    { path: 'asuntos', loadChildren: () => import('./tabs/asuntos/asuntos.module').then(m => m.AsuntosModule) },
    { path: 'consultas', loadChildren: () => import('./tabs/misconsultas/misconsultas.module').then(m => m.MisconsultasModule) },
    { path: 'productos', loadChildren: () => import('./tabs/productos/productos.module').then(m => m.ProductosModule) },
    { path: 'chattheme', loadChildren: () => import('./tabs/themechat/themechat.module').then(m => m.ThemechatModule) },
    { path: 'wallet', loadChildren: () => import('./tabs/abrirconsulta/wall.module').then(m => m.WallModule) },
    { path: 'servicios', loadChildren: () => import('./tabs/servicios/servicios.module').then(m => m.ServiciosModule) },
    { path: 'seguimiento', loadChildren: () => import('./tabs/asuntos/seguimiento/seguimiento.module').then(m => m.SeguimientoModule) },
    { path: 'reclamaciones-indemnizaciones', loadChildren: () => import('./tabs/reclamaciones-indemnizaciones/reclamaciones-indemnizaciones.module').then(m => m.ReclamacionesIndemnizacionesModule) },
    { path: 'investigacion', loadChildren: () => import('./tabs/investigacion/investigacion.module').then(m => m.InvestigacionModule) },
    { path: 'productos', loadChildren: () => import('./tabs/productos/productos.module').then(m => m.ProductosModule) },
    { path: 'white', loadChildren: () => import('./tabs/blank/blank.module').then(m => m.BlankModule) },
    { path: 'perfil', loadChildren: () => import('./tabs/perfil/perfil.module').then(m => m.PerfilModule) },
    { path: 'channels', loadChildren: () => import('./tabs/channels/channels.module').then(m => m.ChannelsModule) },
    { path: 'room', loadChildren: () => import('./tabs/channels/room/room.module').then(m => m.RoomModule) },
    { path: 'auth-redirect', component: AuthRedirectComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ChatRoutingModule { }