<!-- Start chats content -->
<div *ngIf="seccion === 'home'">
  <div class="px-4 pt-4">
    <h4 class="mb-4">Salas de chat</h4>
    <div class="search-box chat-search-box">
      <div class="input-group mb-3 rounded-3">
        <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
          <i class="ri-search-line search-icon font-size-18"></i>
        </span>
        <input type="text" class="form-control bg-light"
          placeholder="Buscar mensajes o usuarios" aria-label="Search messages or users"
          aria-describedby="basic-addon1">
      </div>
    </div>
  </div> <!-- .p-4 -->

  <!-- Start chat-message-list -->
  <div class="px-2">
    <h5 class="mb-3 px-3 font-size-16">Chats recientes</h5>

    <perfect-scrollbar class="chat-message-list">

      <ul class="list-unstyled chat-list chat-user-list">
        <li *ngFor="let user of chat"
          (click)="abrirConsulta(user.idConsulta)">
          <span>
            <div class="d-flex">
              <div class="chat-user-img align-self-center me-3 ms-0">
                <img *ngIf="user.usuario.profile_user.logo !== null" src="{{user.usuario.profile_user.logo}}" class="rounded-circle avatar-xs" alt="">
                <div class="avatar-xs" *ngIf="!user.usuario.profile_user.logo">
                  <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                    {{(user.usuario.email ).charAt(0)}}
                  </span>
                </div>
                <span *ngIf="user.status" class="user-status"></span>
              </div>

              <div class="flex-1 overflow-hidden">
                <h5 class="text-truncate font-size-15 mb-1">{{user.usuario.email}}</h5>
                <p *ngIf="user.isTyping" class="chat-user-message text-truncate mb-0">typing<span
                    class="animate-typing">
                    <span class="dot ml-1"></span>
                    <span class="dot ml-1"></span>
                    <span class="dot ml-1"></span>
                  </span></p>
                <p class="chat-user-message text-truncate mb-0">Asesoria: {{user.tipo_asesoria }}</p>
              </div>
              <div class="font-size-11">{{user.fecha_apertura }}</div>

            </div>
          </span>
        </li>
      </ul>
    </perfect-scrollbar>

  </div>
  <!-- End chat-message-list -->
</div>
<!-- Start chats content -->
<ng-container #detalle></ng-container>