 <!-- Start Groups content -->
 <div>
   <div class="p-4">
     <div class="user-chat-nav float-end">
       <div ngbTooltip="Create group">
         <!-- Button trigger modal -->
         <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0"
           data-toggle="modal" data-target="#addgroup-exampleModal" (click)="openGroupModal(content)">
           <i class="ri-file-add-line"></i>
         </button>
       </div>
     </div>
     <h4 class="mb-4">Preguntas rapidas</h4>

     <!-- Start add group Modal -->
     <ng-template #content let-modal>
       <div class="modal-content">
         <div class="modal-header">
           <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">
             Añadir nueva pregunta rapida</h5>
           <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
             (click)="modal.dismiss('Cross click')">
           </button>
         </div>
         <div class="modal-body p-4">
           <form>
             <div class="mb-4">
               <label class="form-label" for="addgroupname-input">Ingrese la pregunta</label>
               <input type="text" class="form-control" id="addgroupname-input"
                 placeholder="{{'chat.tabs.groups.modal.form.name.placeholder' | translate}}">
             </div>
             <div class="mb-4">
               <label class="form-label">Detalles de la pregunta</label>
               <div class="mb-3">
                 <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                   (click)="isCollapsed = !isCollapsed" data-target="#groupmembercollapse" aria-expanded="false"
                   aria-controls="groupmembercollapse">
                   Seleccionar
                 </button>
               </div>

               <div class="collapse" id="groupmembercollapse" [ngbCollapse]="isCollapsed">
                <div class="card border">
                  <div class="card-header">
                    <h5 class="font-size-15 mb-0">¿Hacer publica esta pregunta?</h5>
                  </div>
                  <div class="card-body p-2">
                    <perfect-scrollbar data-simplebar style="height: 100px;">
                      <div>
                        <ul class="list-unstyled contact-list">
                          <li>
                            <div class="form-check">
                             <input type="radio" class="form-check-input" id="memberCheck1" checked="">
                             <label class="form-check-label" for="memberCheck1">Si</label>
                           </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input type="radio" class="form-check-input" id="memberCheck2">
                              <label class="form-check-label"
                                for="memberCheck2">No</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </perfect-scrollbar>
                  </div>

                </div>
                <div class="card border">
                   <div class="card-header">
                     <h5 class="font-size-15 mb-0">Area legal</h5>
                   </div>
                   <div class="card-body p-2">
                     <perfect-scrollbar data-simplebar style="height: 150px;">
                       <div>
                         <ul class="list-unstyled contact-list">
                           <li>
                             <div class="form-check">
                              <input type="radio" class="form-check-input" id="memberCheck1" checked="">
                              <label class="form-check-label" for="memberCheck1">Penal</label>
                            </div>
                           </li>
                           <li>
                             <div class="form-check">
                               <input type="radio" class="form-check-input" id="memberCheck2">
                               <label class="form-check-label"
                                 for="memberCheck2">Civil</label>
                             </div>
                           </li>
                           <li>
                            <div class="form-check">
                              <input type="radio" class="form-check-input" id="memberCheck2">
                              <label class="form-check-label"
                                for="memberCheck2">Laboral</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input type="radio" class="form-check-input" id="memberCheck2">
                              <label class="form-check-label"
                                for="memberCheck2">Fiscal</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input type="radio" class="form-check-input" id="memberCheck2">
                              <label class="form-check-label"
                                for="memberCheck2">Impagos</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input type="radio" class="form-check-input" id="memberCheck2">
                              <label class="form-check-label"
                                for="memberCheck2">Migración</label>
                            </div>
                          </li>
                          <li>
                            <div class="form-check">
                              <input type="radio" class="form-check-input" id="memberCheck2">
                              <label class="form-check-label"
                                for="memberCheck2">Seguros y Fianzas</label>
                            </div>
                          </li>
                         </ul>
                       </div>
                     </perfect-scrollbar>
                   </div>

                 </div>
               </div>
             </div>
             <div class="form-group">
               <label class="form-label"
                 for="addgroupdescription-input">Descripción</label>
               <textarea class="form-control" id="addgroupdescription-input" rows="3"
                 placeholder="{{'chat.tabs.groups.modal.form.description.placeholder' | translate}}"></textarea>
             </div>
           </form>
         </div>
         <div class="modal-footer">
           <button type="button" class="btn btn-link" data-dismiss="modal"
             (click)="modal.dismiss('Cross click')">Cerrar</button>
           <button type="button"
             class="btn btn-primary">Crear pregunta</button>
         </div>
       </div>
     </ng-template>
     <!-- End add group Modal -->

     <div class="search-box chat-search-box">
       <div class="input-group mb-3 rounded-3">
           <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
             <i class="ri-search-line search-icon font-size-18"></i>
           </button>
         <input type="text" class="form-control bg-light"
           placeholder="Buscar pregunta publica">
       </div>
     </div>
     <!-- end search-box -->
   </div>
   <div class="p-4">
   Top ten<br>
   <h5>Preguntas publicas</h5>
   <span class="badge rounded-pill bg-danger">Fiscal</span> &nbsp;
   <span class="badge rounded-pill bg-danger">Penal</span> &nbsp;
<span class="badge rounded-pill bg-danger">Civil</span> &nbsp;
<span class="badge rounded-pill bg-danger">Laboral</span> &nbsp;
<span class="badge rounded-pill bg-danger">Impagos</span> &nbsp;
<span class="badge rounded-pill bg-danger">Administrativo</span>&nbsp; 
<span class="badge rounded-pill bg-danger">Seguros y Fianzas</span> &nbsp;
<span class="badge rounded-pill bg-danger">Extranjeria</span>
 
  </div>
   <!-- Start chat-group-list -->
   <perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar>
     <ul class="list-unstyled chat-list">
       <li>
         <a href="#">
           <div class="d-flex align-items-center">
             <div class="flex-1 overflow-hidden">
               <h5 class="font-size-14 mb-0">1.- ¿A partir de que edad un niño puedo escoger con quien vivir?
                 <span class="badge badge-soft-danger rounded-pill float-end">0</span>
               </h5>
             </div>
           </div>
         </a>
       </li>
       <li>
        <a href="#">
          <div class="d-flex align-items-center">
            <div class="flex-1 overflow-hidden">
              <h5 class="font-size-14 mb-0">2.- ¿Mi ex no paga manutención, puedo negarle ver a los niños?
                <span class="badge badge-soft-danger rounded-pill float-end">0</span>
              </h5>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="d-flex align-items-center">
            <div class="flex-1 overflow-hidden">
              <h5 class="font-size-14 mb-0">3.- ¿Puedo renunciar a mis derechos como padre?
                <span class="badge badge-soft-danger rounded-pill float-end">6</span>
              </h5>
            </div>
          </div>
        </a>
      </li>
      <li>
        <a href="#">
          <div class="d-flex align-items-center">
            <div class="flex-1 overflow-hidden">
              <h5 class="font-size-14 mb-0">4.- ¿Hasta cuando puedo pasar la manutención?
                <span class="badge badge-soft-danger rounded-pill float-end">3</span>
              </h5>
            </div>
          </div>
        </a>
      </li>
     </ul>
   </perfect-scrollbar>
   <!-- End chat-group-list -->
 </div>
 <!-- End Groups content -->