<div class="d-lg-flex half">
  <div
    class="bg order-1 order-md-2"
    style="background-image: url('assets/images/bg_1.jpg')"
  ></div>
  <div class="contents order-2 order-md-1">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-7">
          <span style="text-align: center">
            <h3>Ingrese a <strong>CuartoCastejo</strong></h3>
            <p class="mb-4">
              Tu asesor legal 24/7 - Socios legales y estratégicos
            </p>
          </span>
          <div class="card">
            <div class="card-body p-4">
              <div class="p-3">
                <ngb-carousel [showNavigationIndicators]="true" [prevIcon]="'<i class=\'bi bi-arrow-left-circle-fill text-black\'></i>'" [nextIcon]="'<i class=\'bi bi-arrow-right-circle-fill text-black\'></i>'">
                  <ng-template ngbSlide *ngFor="let item of actualizaciones">
                    <div style="text-align: center;">{{ item.titulo }}</div>
                    <p style="text-align: center;">{{ item.descripcion }}</p>
                  </ng-template>
                </ngb-carousel>

                <button class="buttonload" (click)="startLogin()">
                  Entrar
                  <i *ngIf="btnLock === 1" class="fa fa-lock loader"></i>
                  <i
                    *ngIf="btnLock === 2"
                    class="fa fa-circle-o-notch fa-spin loader"
                  ></i>
                  <i *ngIf="btnLock === 3" class="fa fa-unlock loader"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>
              © Cuartocastejo · Contacto · Aviso de privacidad · Términos y
              condiciones
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
