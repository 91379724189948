<app-scheleton *ngIf="load"></app-scheleton>
<div  *ngIf="!load" class="user-profile-sidebar" id="profile-detail">
  <div class="px-3 px-lg-4 pt-3 pt-lg-4">
    <div class="user-chat-nav text-end">
    </div>
  </div>

  <div class="text-center p-1 border-bottom">
    <div class="mb-4">
      <img src="assets/images/users/avatar-4.jpg" class="rounded-circle avatar-lg img-thumbnail" alt="">
    </div>
    <h5 class="font-size-16 mb-1 text-truncate">Daniel Rodríguez</h5>
    <p class="text-muted text-truncate mb-1"><i class="ri-record-circle-fill font-size-10 text-success me-1"></i>
      Activo</p>
  </div>
  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <perfect-scrollbar class="p-1 user-profile-desc">
    <div class="text-muted">
      <p class="mb-4"> Mi perfil</p>
    </div>

    <div id="profile-user-accordion" class="custom-accordion">

      <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
        <ngb-panel cardClass="card shadow-none border mb-2" id="about">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
              Información personal
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <form id="contactForm" [formGroup]="formProfile" (ngSubmit)="submit()">
              <div>
                <mat-card class="contact-card" style="width:100%">
                  <mat-card-content>
                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Nombre" formControlName="nombre" maxlength="45" class="form-control"
                        name="nombre">
                    </mat-form-field>
                    <br>
                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Apellidos" formControlName="cedula_profesional" maxlength="85"  class="form-control" name="cedula_profesional">
                    </mat-form-field>
                    <br>
                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="Ciudad" formControlName="formacion" class="form-control" maxlength="45"  name="formacion">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="logo" formControlName="logo" class="form-control" name="logo">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="sedes" formControlName="sedes" class="form-control" maxlength="250"  name="sedes">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="descripcion" formControlName="descripcion" class="form-control" maxlength="250" name="descripcion">
                    </mat-form-field>

                    <mat-form-field style="width: 100%;">
                      <input matInput placeholder="telefono" formControlName="telefono" class="form-control" maxlength="10" name="telefono">
                    </mat-form-field>
                    <br>
                  </mat-card-content>
                </mat-card>

              </div>
              <mat-dialog-actions align="end">
                <button id="btnSubmit" mat-raised-button type="submit" color="primary" cdkFocusInitial [disabled]="submitted" class="buttonload btn">Entrar
                  <i *ngIf="btnLock === 1" class="fa fa-lock loader"></i>
                  <i *ngIf="btnLock === 2" class="fa fa-circle-o-notch fa-spin loader"></i>
                  <i *ngIf="btnLock === 3" class="fa fa-unlock loader"></i></button>
                <button id="btnClose" [mat-dialog-close]="response" style="display: none;" mat-raised-button
                  color="primary">Cerrar</button>
              </mat-dialog-actions>
            </form>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- End Attached Files card -->
    </div>
    <!-- end profile-user-accordion -->
  </perfect-scrollbar>
  <!-- end user-profile-desc -->
</div>