import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { Chats } from './chats.model';
import { HttpClient } from '@angular/common/http';
import { SeguimientoComponent } from '../asuntos/seguimiento/seguimiento.component';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
/**
 * Tab-chat component
 */
export class ChatsComponent implements OnInit {
  seccion = 'home';
  chat: Chats[];
  error;
  @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
  private _ref;

  constructor(private publicService: PublicService, private http: HttpClient,private factoryResolver: ComponentFactoryResolver) { }


  ngOnInit(): void {
    this.publicService.get_chat().subscribe(data => {
        console.log(data);
        this.chat = data.items;
      }, error => this.error = error);

  }

  abrirConsulta(id) {
    this.seccion = 'inicio';
    const factory = this.factoryResolver.resolveComponentFactory(SeguimientoComponent);
    this._ref = this.detalle.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.tabId = 'chat';
    this._ref.instance.cerrar.subscribe(data => {
      console.log(data);
      this.detalle.clear();
    });
  }
}