<div *ngIf="seccion === 'home'">
  <div id="header01">
    <textarea id="id1" style="display: none">https://esug.mx/legal/fiscal.jpg</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarPractica()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left"
                width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <path
                    d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                  <path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                  <path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                </g>
              </svg></span>
            <span class="abrir-producto">Mis consultas</span>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Start profile content -->
  <div class="container">
    Canales<br>
    Interactua con colegas de tu especialidad<br>
    <div class="ctn">
      <div class="title">Canales de practicas</div>
      <span class="bloq" (click)="abrirRoom('CHAT_FISCAL')">#Fiscal y Administrativo<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_PENAL')"># Practica penal<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_CIVIL')">#Civil<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_LABORAL')">#Laboral<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_IMPAGOS')">#Impagos<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_EXTRANJERIA')">#Extranjeria<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_SEGUROS')">#Seguros y Fianzas<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_CONSTITUCIONAL')">#Constitucional<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_CORPORATIVO')">#Corporativo<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_PI')">#Propiedad Intelectual<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_COMERCIOEXT')">#Comercio Exterior<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_BANCARIO')">#Bancario y Financiero<br></span>

      <br>
      <div class="title">Canales Partner</div><br>

      <span class="bloq" (click)="abrirRoom('CHAT_PROPUESTAS')"># Propuestas partners<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_PRESENTACIONES')"># Presentaciones BranchesLegal<br></span>
      <span class="bloq" (click)="abrirRoom('CHAT_NOTICIAS')"># Noticias del sector legal<br></span>
    </div>

  </div>
</div>

<ng-container #practica></ng-container>