import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  form;
  formProfile;
  user;
  info_fiscal;
  response;
  updateProfile: string = environment.api + '/private/perfilawyers';
  url: string = environment.api + '/private/metodosdepagos?offset=0&limit=20';
  error;
  prueba = 'prueba';
  submitted = false;
  btnLock = 1;
  email: any;
  load = true;
  idUsuario: any;

  constructor(private publicService: PublicService, private http: HttpClient, private formBuilder: FormBuilder, public dialog: MatDialog) {
  }
  ngOnInit(): void {
    this.cargarInfoProfile();
  }

  hideUserProfile() {
    document.getElementById('profile-detail').style.display = 'none';
  }

  cargarInfoProfile(): void {
    this.publicService.get_perfil_lawyers().subscribe(data => {
      console.log(data);
      this.email = data.email;
      this.idUsuario = data.profile_lawyer.id;
      this.user = data.profile_lawyer;
      this.formProfile = this.formBuilder.group({
        nombre: [this.user.nombre, Validators.required],
        cedula_profesional: [this.user.cedula_profesional, Validators.required],
        formacion: [this.user.formacion, Validators.required],
        logo: [this.user.logo, Validators.required],
        sedes: [this.user.sedes, Validators.required],
        descripcion: [this.user.descripcion, Validators.required],
        telefono: [this.user.telefono, Validators.required]
      });
      this.load = false;
    }, error => this.error = error);
  }

  submit() {
    console.log('Aqui');
    const d = this.dialog.open(ConfirmAlert, {
      width: '320px',
      data: { isTrue: true },
    });
    d.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        console.log('enter');
        this.submitted = true;
        this.btnLock = 2;
        this.response = {
          "avanzar": true,
          "nombre": this.formProfile.value.nombre,
          "email": this.email,
          "cedula_profesional": this.formProfile.value.cedula_profesional,
          "formacion": this.formProfile.value.formacion,
          "ciudad": this.formProfile.value.ciudad,
          "sedes": this.formProfile.value.sedes,
          "logo": this.formProfile.value.logo,
          "descripcion": this.formProfile.value.descripcion,
          "telefono": this.formProfile.value.telefono
        };
        console.log(this.response);
        this.publicService.patch_profile(this.idUsuario, this.response).subscribe(res => {
          window.location.reload();
        }, error => this.error = error);

        setTimeout(() => { document.getElementById('btnClose').click() }, 1000);
      }
    });
  }
}



@Component({
  selector: 'ConfirmAlert',
  template: `<h1 mat-dialog-title>¿Desea actualizar su información de perfil?</h1>
  <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Gracias</button>
  <button mat-button [mat-dialog-close]="data.isTrue" cdkFocusInitial>Si</button>
</div>`,
})
export class ConfirmAlert {
  constructor(public dialogRef: MatDialogRef<ConfirmAlert>, @Inject(MAT_DIALOG_DATA) public data: DialogD,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogD {
  isTrue: boolean;
}