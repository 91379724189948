import { Component, OnInit } from '@angular/core';
import authgear from '@authgear/web';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  // configure Authgear container instance
  initAuthgear(): Promise<void> {
    return authgear.configure({
      endpoint: 'https://cuartocastejo.authgearapps.com',
      clientID: '1a08adba3c6e89be',
      sessionType: 'refresh_token',
    });
  }

  ngOnInit(): void {
    this.initAuthgear().catch((e) => {
      // Error handling
      console.log(e);
    });
  }
}
