<div class="list-wrapper">
    <ul>
            <!-- Loading Items -->
            <li class="skeleton" *ngFor="let item of generateFake(5)">
                <div class="item-wrap">
                    <div class="item-img">
                        <ngx-skeleton-loader appearance="circle" [theme]="{ height: '100px',width: '100px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="item-content">
                        <div>
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                        </div>
                        <p>
                            <ngx-skeleton-loader [theme]="{ height: '30px'}"></ngx-skeleton-loader>
                        </p>
                    </div>
                </div>
            </li>
    </ul>
</div>