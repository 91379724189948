import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheleton',
  templateUrl: './scheleton.component.html',
  styleUrls: ['./scheleton.component.scss']
})
export class ScheletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

}
