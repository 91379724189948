import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver  } from '@angular/core';
import { AperturaComponent } from './../apertura/apertura.component';
import { HttpClient } from '@angular/common/http'; // <----
import { environment } from '../../../../../../src/environments/environment';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
/**
 * Tabs-Profile component
 */
export class DetalleComponent implements OnInit {
  @Output () abrir: EventEmitter<string> = new EventEmitter();
  @Output () cerrarDetalle: EventEmitter<string> = new EventEmitter();
  listPracticas: any;
  practicaTitulo: string;
  @Input () detalleId: string;
  private _ref;
  seccion = 'home';
  error;
  detalle;
  lawyers;
  tendencias;
  ancho = 178;
  url: string = '';

  @ViewChild('apertura', { read: ViewContainerRef }) apertura: ViewContainerRef;

  

  constructor(public publicService: PublicService, private factoryResolver: ComponentFactoryResolver, private http: HttpClient) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.cargarDetalle();
    this.cargarLawyers();
    this.cargarTendencias();
  }

  cargarLawyers():void { 
    this.publicService.get_profile_lawyers().subscribe(data => {
      console.log(data);
      this.lawyers = data.items;     
    },error => this.error = error);
  }

  cargarTendencias():void {
    this.publicService.get_tendencias().subscribe(data => {
      console.log(data);
      this.tendencias = data.items;
   },error => this.error = error);
  }

  cargarDetalle():void {
    this.publicService.get_tipos_casos(this.detalleId).subscribe(data => {
      console.log(data);
      this.detalle = data;
   },error => this.error = error);
  }

  abrirApertura(id): void {
    this.seccion = 'inicio';

    console.log(id);

    const factory = this.factoryResolver.resolveComponentFactory(AperturaComponent);
    this._ref = this.apertura.createComponent(factory);

    this._ref.instance.apertura = id;

    this._ref.instance.cerrarDetalle.subscribe(data => {
        console.log(data);
        this.apertura.clear();
        this.seccion = 'home';
    });
  }

  cerrarDetail(): void {
    this.cerrarDetalle.emit('true');
  }

}
