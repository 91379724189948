import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver  } from '@angular/core';
import { PublicService } from 'src/app/public.service';
import { environment } from '../../../../../src/environments/environment';
import { SeguimientoComponent } from '../asuntos/seguimiento/seguimiento.component';

@Component({
  selector: 'app-investigacion',
  templateUrl: './investigacion.component.html',
  styleUrls: ['./investigacion.component.scss']
})
export class InvestigacionComponent implements OnInit {

  load = true;
  url: string = environment.api + '/private/casoslawyers?search=investigacion';
  reclaims;
  error;
  seccion = 'home';
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  private _ref;


  constructor(private publicService: PublicService, private http: HttpClient, private factoryResolver: ComponentFactoryResolver) { }
  ngOnInit(): void {
    this.load = false;
    this.cargarMisConsultas();
  }

  cargarMisConsultas(): void {
    this.publicService.get_reclamaciones().subscribe(data => {
      console.log(data.items);
      this.reclaims = data.items;
   },error => this.error = error);
  }

  abrirPractica(id): void {
    console.log(id);
    this.seccion = 'inicio';

    const factory = this.factoryResolver.resolveComponentFactory(SeguimientoComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
        this.practica.clear();
        this.seccion = 'home';
    });
  }


}
