<div class="p-3" style="width: 100%;">
    <h3>Hola, Daniel!</h3><br>
    Estas son las primeras acciones que puedes tomar para para comenzar tu integración.<br><br>
    <h2 class="kvMhqE">Centro de integración</h2><br>
    <div elevation="0" spacing="s_32" class="sc-4wqkuy-0 kiqPyJ sc-1qa7ykr-1 drPIre">
        <div
            class="sc-1qa7ykr-2 jxQdvP"></div>
        <div>
            <div class="sc-1re405y-0 gMkjUi">
                <div class="sc-1re405y-1 cluFOw">Completa tu perfil y comienza a aperturar de inmediato</div>
            </div>
            <div class="sc-k407ft-0 copjsf"></div>
            <br>
            <p>Explora nuestros distintos servicios legales,  puedes aperturar una consulta, hacer una pregunta rapida o bien contratar un producto legal.</p>
            <div class="sc-k407ft-0 iOedXE"></div>
        </div>
    </div>
    <div class="et_pb_section et_pb_section_3 et_section_regular">
        <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_11">
            <div class="et_pb_module_inner">
              <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
                <div class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center">
                  <div class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center" data-svg-shape=""
                    data-shape-size="contain" data-use-upload="off" data-uploaded-mask=""><img
                      src="assets/images/automation-tools-homepage-min.webp" alt=""
                      class="img-fluid"></div>
                  <div class="">
                    <div class="dnxt-blurb-container">
                      <div class="dnxt-blurb-heading-wrapper">
                        <h2 class="dnxt-blurb-heading">Investigación legal</h2>
                      </div>
                      <div class="dnxt-blurb-description">
                        <p>Aperture una reclamación o indemnización con nuestro modelo de negocio win to win, solo cobramos si recuperamos lo reclamado.</p>
                      </div>
                      <div class="dnxt-button-wrapper"><a href="/lawpath-platform"
                          class="dnxt-blurb-btn dnxt-btn-icon       dnxt-hover-bob" target="">Comenzar</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_10">
            <div class="et_pb_module_inner">
              <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
                <div class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center">
                  <div class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center" data-svg-shape=""
                    data-shape-size="contain" data-use-upload="off" data-uploaded-mask=""><img
                      src="assets/images/hire-a-lawyer-homepage-min.webp" alt=""
                      class="img-fluid"></div>
                  <div class="">
                    <div class="dnxt-blurb-container">
                      <div class="dnxt-blurb-heading-wrapper">
                        <h2 class="dnxt-blurb-heading">Proporcionar consultoría                        </h2>
                      </div>
                      <div class="dnxt-blurb-description">
                        <p>Nuestros abogados expertos aman lo que hacen y siempre están listos para brindarle el asesoramiento que necesita para seguir construyendo su negocio. No hay robots aquí.                        </p>
                      </div>
                      <div class="dnxt-button-wrapper"><a href="/hire-a-lawyer"
                          class="dnxt-blurb-btn dnxt-btn-icon       dnxt-hover-bob" target="">Comenzar</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_9">
              <div class="et_pb_module_inner">
                <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
                  <div class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center">
                    <div class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center" data-svg-shape=""
                      data-shape-size="contain" data-use-upload="off" data-uploaded-mask=""><img
                        src="assets/images/Legal-Documents-homepage-min.webp" alt=""
                        class="img-fluid"></div>
                    <div class="">
                      <div class="dnxt-blurb-container">
                        <div class="dnxt-blurb-heading-wrapper">
                          <h2 class="dnxt-blurb-heading">Productos legales</h2>
                        </div>
                        <div class="dnxt-blurb-description">
                          <p>No solo puede crear contratos con un clic, sino que también puede hacerlos vinculantes. Puede redactar, enviar y firmar, todo desde una plataforma práctica.

                        </p>
                        </div>
                        <div class="dnxt-button-wrapper"><a href="/legal-documents/solutions"
                            class="dnxt-blurb-btn dnxt-btn-icon       dnxt-hover-bob" target="">Comenzar</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div class="et_pb_with_border et_pb_module dnxte_blurb dnxte_blurb_8">
          <div class="et_pb_module_inner">
            <div class="dnxt-blurb-wrapper-outer dnxt-hover-bob">
              <div class="dnxt-blurb-wrapper dnxt-blurb-wrapper-one_image_placement_top-center">
                <div class="dnxt-blurb-image dnxt-blurb-image_image_placement_top-center" data-svg-shape=""
                  data-shape-size="contain" data-use-upload="off" data-uploaded-mask=""><img
                    src="assets/images/start-a-business-homepage-min.webp" alt=""
                    class="img-fluid"></div>
                <div class="">
                  <div class="dnxt-blurb-container">
                    <div class="dnxt-blurb-heading-wrapper">
                      <h2 class="dnxt-blurb-heading">Jurimetría</h2>
                    </div>
                    <div class="dnxt-blurb-description">
                      <p><span style="font-weight: 400;">Toma tu idea y conviértela en un negocio registrando una empresa usando Branches Legal. Te guiamos a través de todo lo que necesitas para que tu negocio sea oficial.

                    </span></p>
                    </div>
                    <div class="dnxt-button-wrapper"><a href="/services"
                        class="dnxt-blurb-btn" target="">Comenzar</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>