import { Component, OnInit, Output, Input, EventEmitter, Inject, ComponentFactoryResolver, ViewContainerRef,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../../src/environments/environment';
import { SeguimientoComponent } from './seguimiento/seguimiento.component';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-asuntos',
  templateUrl: './asuntos.component.html',
  styleUrls: ['./asuntos.component.scss']
})

export class AsuntosComponent implements OnInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrarDetalle: EventEmitter<string> = new EventEmitter();
  seccion = 'home';
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  practicaId = 'fiscal';
  private _ref;
  listAsuntos: any;
  error;
  load = true;
  url: string = environment.api + '/private/casoslawyers?search=consulta';

  constructor(private publicService: PublicService, private router: Router, private http: HttpClient, private factoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.cargarPractica();
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  cargarPractica(): void {
    this.publicService.get_search_consulta().subscribe(data => {
        console.log(data);
        this.load = false;
        this.listAsuntos = data.items;
     },error => this.error = error);
  }

  cerrarApertura(): void {
    this.cerrarDetalle.emit('true');
  }

  abrirPractica(id): void {
    console.log(id);
    this.seccion = 'inicio';

    const factory = this.factoryResolver.resolveComponentFactory(SeguimientoComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
        this.practica.clear();
        this.seccion = 'home';
    });
  }

}
