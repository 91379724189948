<div *ngIf="seccion === 'home'">
  <div id="header01">
    <textarea id="id1" style="display: none">https://esug.mx/legal/fiscal.jpg</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarPractica()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left"
                width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <path
                    d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                  <path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                  <path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                </g>
              </svg></span>
            <span class="abrir-producto">Mis consultas</span>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Start profile content -->
  <mat-tab-group [color]="colorToggle.value" [backgroundColor]="backgroundColorToggle.value">
    <mat-tab label="Expedientes">

<button mat-fab color="primary" (click)="clickMenu()"
    class="menu-button"
    [class.mat-elevation-z2]="!isOver"
    [class.mat-elevation-z8]="isOver"
    (mouseover)="isOver = true"
    (mouseleave)="isOver = false">
  <i class="material-icons" *ngIf="!openMenu">menu</i>
  <i class="material-icons" *ngIf="openMenu">close</i>
</button>



<button mat-mini-fab 
  class="action-button two mat-elevation-z8"    
  *ngIf="openMenu"
  color="basic" 
  (click)="nuevoExpediente()">
  <i class="material-icons">done_all</i>
</button>

<button mat-raised-button 
  class="label labeltwo mat-elevation-z8" 
  *ngIf="openMenu"
  color="basic" 
  (click)="nuevoExpediente()">
  Añadir expediente
</button>

      <div class="p-3 cardsa">
        <div *ngFor="let expediente of expedientes" class="shadow p-2 mb-3 bg-white rounded">
          <div class="tituloct">{{expediente.titulo}}</div>
          <br>
          {{expediente.createdAt | date: 'dd/MM/yyyy hh:mm'}}
          <br><br>
          {{expediente.descripcion | slice:0:(expediente.more ? undefined : 100)}} <span  style="color:blue;" (click)="expediente.more = !expediente.more">{{ expediente.more ? '[Leer menos]':'..[Leer más]' }}</span>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Contacto">
      <div class="item" style="height: calc(100vh - 200px);  width: 380px;">
        <div class="pm">
          <div class="padre">
            <div class="hijo d-flex align-items-center">
              <div class="d-block d-lg-none me-2 ms-0">
                <svg version="1.1" width="15px" height="15px" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 92.25 122.88"
                  style="enable-background:new 0 0 92.25 122.88" xml:space="preserve">
                  <style type="text/css">
                    .st0 {
                      fill-rule: evenodd;
                      clip-rule: evenodd;
                    }
                  </style>
                  <g>
                    <path class="st0"
                      d="M68.51,106.28c-5.59,6.13-12.1,11.62-19.41,16.06c-0.9,0.66-2.12,0.74-3.12,0.1 c-10.8-6.87-19.87-15.12-27-24.09C9.14,86.01,2.95,72.33,0.83,59.15c-2.16-13.36-0.14-26.22,6.51-36.67 c2.62-4.13,5.97-7.89,10.05-11.14C26.77,3.87,37.48-0.08,48.16,0c10.28,0.08,20.43,3.91,29.2,11.92c3.08,2.8,5.67,6.01,7.79,9.49 c7.15,11.78,8.69,26.8,5.55,42.02c-3.1,15.04-10.8,30.32-22.19,42.82V106.28L68.51,106.28z M46.12,23.76 c12.68,0,22.95,10.28,22.95,22.95c0,12.68-10.28,22.95-22.95,22.95c-12.68,0-22.95-10.27-22.95-22.95 C23.16,34.03,33.44,23.76,46.12,23.76L46.12,23.76z" />
                  </g>
                </svg>
              </div>
              <div class="flex-grow-1 overflow-hidden p-3">
                <span class="tl1">ADDRESS</span><br>
                <span class="tl2">3-277-10 Susan Apartment<br>
                  Liverpool, United Kingdom</span>
              </div>
            </div>
          </div>
          <div class="padre">
            <div class="hijo d-flex align-items-center">
              <div class="d-block d-lg-none me-2 ms-0">
                <svg version="1.1" width="15px" height="15px" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.27"
                  style="enable-background:new 0 0 122.88 122.27" xml:space="preserve">
                  <g>
                    <path
                      d="M33.84,50.25c4.13,7.45,8.89,14.6,15.07,21.12c6.2,6.56,13.91,12.53,23.89,17.63c0.74,0.36,1.44,0.36,2.07,0.11 c0.95-0.36,1.92-1.15,2.87-2.1c0.74-0.74,1.66-1.92,2.62-3.21c3.84-5.05,8.59-11.32,15.3-8.18c0.15,0.07,0.26,0.15,0.41,0.21 l22.38,12.87c0.07,0.04,0.15,0.11,0.21,0.15c2.95,2.03,4.17,5.16,4.2,8.71c0,3.61-1.33,7.67-3.28,11.1 c-2.58,4.53-6.38,7.53-10.76,9.51c-4.17,1.92-8.81,2.95-13.27,3.61c-7,1.03-13.56,0.37-20.27-1.69 c-6.56-2.03-13.17-5.38-20.39-9.84l-0.53-0.34c-3.31-2.07-6.89-4.28-10.4-6.89C31.12,93.32,18.03,79.31,9.5,63.89 C2.35,50.95-1.55,36.98,0.58,23.67c1.18-7.3,4.31-13.94,9.77-18.32c4.76-3.84,11.17-5.94,19.47-5.2c0.95,0.07,1.8,0.62,2.25,1.44 l14.35,24.26c2.1,2.72,2.36,5.42,1.21,8.12c-0.95,2.21-2.87,4.25-5.49,6.15c-0.77,0.66-1.69,1.33-2.66,2.03 c-3.21,2.33-6.86,5.02-5.61,8.18L33.84,50.25L33.84,50.25L33.84,50.25z" />
                  </g>
                </svg>
              </div>
              <div class="flex-grow-1 overflow-hidden p-3">
                <span class="tl1">PHONE</span><br>
                <span class="tl2">+01 123456565/+01328327834</span>
              </div>
            </div>
          </div>
          <div class="padre">
            <div class="hijo d-flex align-items-center">
              <div class="d-block d-lg-none me-2 ms-0">
                <svg id="Layer_1" width="15px" height="15px" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 122.88 122.88">
                  <title>email-square-line</title>
                  <path
                    d="M26.3,0H96.58a26.36,26.36,0,0,1,26.3,26.3V96.58a26.36,26.36,0,0,1-26.3,26.3H26.3A26.36,26.36,0,0,1,0,96.58V26.3A26.36,26.36,0,0,1,26.3,0Zm7,38.65h56.3a3.65,3.65,0,0,1,3.64,3.64V81a3.57,3.57,0,0,1-.63,2,1.46,1.46,0,0,1-.34.52,1.51,1.51,0,0,1-.34.24,3.7,3.7,0,0,1-2.33.85H33.29a3.63,3.63,0,0,1-2.57-1.07A3.67,3.67,0,0,1,29.65,81V42.29a3.65,3.65,0,0,1,3.64-3.64Zm-.85,40.79L52.17,59.67,32.44,43.58V79.44Zm21.9-18L34,81.83H88.7L69.27,61.44,62.86,66.9h0a1.39,1.39,0,0,1-1.79,0l-6.73-5.48Zm17-1.81,19,20V43.41l-19,16.22ZM34.24,41.44,61.93,64,88.44,41.44ZM96.58,3.72H26.3A22.67,22.67,0,0,0,3.72,26.3V96.58A22.67,22.67,0,0,0,26.3,119.16H96.58a22.67,22.67,0,0,0,22.58-22.58V26.3A22.67,22.67,0,0,0,96.58,3.72Z" />
                </svg>
              </div>
              <div class="flex-grow-1 overflow-hidden p-3">
                <span class="tl1">EMAIL</span><br>
                <span class="tl2">cindre12@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Chat">
      <div class="item" style="height: calc(alto);  width: 380px;">
        <ng-container #chat [id]="room"></ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>

  <ng-container #detalle></ng-container>