import { ThemeChatComponent } from './../../themechat/themechat.component';
import { Component, OnInit, Output, Input, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogData } from '../../abrirconsulta/apertura/apertura.component';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-seguimiento-consulta',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.scss']
})
/**
 * Tabs-Profile component
 */
export class SeguimientoComponent implements OnInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrar: EventEmitter<string> = new EventEmitter();
  listPracticas: any;
  practicaTitulo: string;
  @Input() practicaId: string;
  @Input() tabId: string;
  private _ref;
  activo = '1';
  seccion = 'home';
  room = '0';
  more = false;
  ancho = window.innerWidth;
  alto = window.innerHeight;
  error;
  colorToggle = 'primary';
  backgroundColorToggle = 'primary';
  number = 134;
  expedientes;
  urlChat = "https://central-serviceslb.herokuapp.com/private/chats/full/";
  urlExpediente = "https://central-serviceslb.herokuapp.com/private/expedientes/detail/";
  @ViewChild('detalle', { read: ViewContainerRef }) detalle: ViewContainerRef;
  @ViewChild('chat', { read: ViewContainerRef }) chat: ViewContainerRef;
  @ViewChild('owlCar', { static: false }) owlCar: any;


  constructor(private publicService: PublicService, private factoryResolver: ComponentFactoryResolver, public dialog: MatDialog, private http: HttpClient) { }
  public openMenu: boolean = false;
  isOver = false;

  clickMenu() {
    this.openMenu = !this.openMenu;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    console.log(this.practicaId);
    this.cargarChat(this.practicaId);
    if (this.practicaId === 'penal') {
      this.practicaTitulo = 'Penal';
      this.listPracticas = [];
    } else if (this.practicaId === 'seguros') {
      this.listPracticas = [];
    } else {
      this.listPracticas = [];
    }
    setTimeout(() => {
      // Crea componente de chat
      const factory = this.factoryResolver.resolveComponentFactory(ThemeChatComponent);
      this._ref = this.chat.createComponent(factory);
      this._ref.instance.id = this.room;
    }, 4000);
    if (this.tabId === 'chat') {
      this.activo = '3';
      setTimeout(() => { document.getElementById('btn').click() }, 2000);
    }
  }

  getData(data: any) {
    console.log(data);
  }

  cargarChat(id): void {
    this.publicService.get_chats_full(id).subscribe(data => {
      console.log(data);
      this.room = data.nombre_room;
      this.cargarExpedientes(this.room);
    }, error => this.error = error);
  }

  cargarExpedientes(room): void {
    this.publicService.get_url_expediente(room).subscribe(data => {
      console.log(data);
      let exp = [];
      for (let e of data) {
        e.more = false;
        exp.push(e);
      }
      this.expedientes = exp;
    }, error => this.error = error);
  }

  abrirPractica(id): void {
    this.seccion = 'inicio';
  }

  cerrarPractica(): void {
    this.cerrar.emit('true');
  }

  nuevoExpediente(): void {
    const dialogRef = this.dialog.open(DialogElementsAgregarExpedienteDialog, {
      width: '450px',
      data: {room: this.room,},
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.cargarExpedientes(this.room);
    });
  }

}

@Component({
  selector: 'dialog-elements-agregar-expediente-dialog',
  styles: [`.fill-remaining-space {
    /* This fills the remaining space, by using flexbox. 
       Every toolbar row uses a flexbox row layout. */
    flex: 1 1 auto;
  }
  
  .container {
    padding: 10px;
  }
  
  .form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
  }
  
  .form-element {
    padding: 5px 0px 25px 2px;
    width: 100%;
  }
  
  .button {
    width: 100%;
  }`],
  template: `<mat-toolbar color="primary">
  <span class="fill-remaining-space">Añadir un nuevo expediente</span>
</mat-toolbar>

<div class="container" *ngIf="!post; else forminfo" novalidate> 
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)" class="form">

    <mat-form-field class="form-element">
      <input matInput maxlength="65" placeholder="Título" formControlName="titulo">
      <mat-error *ngIf="!titulo.valid && titulo.touched">
        {{ titleAlert }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form-element">
      <textarea matInput placeholder="Descripción" matTextareaAutosize matAutosizeMinRows="10" matAutosizeMaxRows="10" formControlName="descripcion"></textarea>
      <mat-error *ngIf="!formGroup.controls['descripcion'].valid && formGroup.controls['descripcion'].touched">
        Required field, must be between 5 and 10 characters.
      </mat-error>
    </mat-form-field>

    <div class="form-element">
    <button mat-raised-button color="primary" type="submit" class="button" [disabled]="!formGroup.valid">Enviar expediente</button><br><br>
    <button mat-button (click)="close()" class="button">Cerrar</button>
    </div>

  </form>
</div>

<ng-template #forminfo>
  <div class="container">
    {{ post }}   <button mat-button mat-dialog-close>Cerrar</button>
  </div>
</ng-template>`
})
export class DialogElementsAgregarExpedienteDialog {

  formGroup: FormGroup;
  titleAlert: string = 'This field is required';
  post: any = '';
  error;
  urlExpedientePost = "https://central-serviceslb.herokuapp.com/private/expedientes";
  room;

  constructor(private publicService: PublicService, private formBuilder: FormBuilder, private http: HttpClient,     public dialogRef: MatDialogRef<SeguimientoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.room = this.data;
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'titulo': [null, Validators.required],
      'descripcion': [null, [Validators.required]],
      'validate': ''
    });
  }

  get titulo() {
    return this.formGroup.get('titulo') as FormControl
  }

  onSubmit(post) {
    let obj = {
      "room": this.room.room,
      "titulo": post.titulo,
      "descripcion": post.descripcion
    };
    console.log(obj);
    this.publicService.post_url_expediente_detail(obj).subscribe(data => {
      console.log(data);
      this.post = "Su expediente se ha añadido exitosamente.";
    }, error => this.error = error);
  }
  
  close(): void {
    this.dialogRef.close();
  }
  

}