import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { environment } from '../../../../environments/environment';
import 'firebase/storage';  // If using Firebase storage
import { HttpClient } from '@angular/common/http';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { UserService } from 'src/app/services/user.service';
import authgear from '@authgear/web';
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [RecaptchaLoaderService]
})

/**
 * Login component
 */
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  error = '';
  captchaIsTrue = false;
  returnUrl: string;
  actualizaciones;
  submitted = false;
  btnLock = 1;
  // set the currenr year
  year: number = new Date().getFullYear();

  // tslint:disable-next-line: max-line-length
  constructor(private dataService: PublicService, public user: UserService, private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private http: HttpClient, private router: Router, public authenticationService: AuthenticationService, public authFackservice: AuthfakeauthenticationService) { }


  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.dataService.get_actualizaciones().subscribe((data: any) => {
      this.actualizaciones = data.items;
      console.log(data);
    }, error => this.error = error);
    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }


  startLogin(): void {
    this.btnLock = 2;
    authgear
      .startAuthentication({
        redirectURI: 'http://localhost:4201/auth-redirect'
      })
      .then(
        () => {
          this.btnLock = 1;
          // started authorization, user should be redirected to Authgear
        },
        (err) => {
          // failed to start authorization
          this.btnLock = 1;
          console.error(err);
        }
      );
  }
  /**
   * Form submit
   */
  onSubmit() {
    this.btnLock = 2;

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid || !this.captchaIsTrue) {
      this.submitted = false;
      this.btnLock = 1;
      return;
    } else {
        this.authFackservice.login(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          data => {
              localStorage.setItem('nickname', this.f.email.value);
              this.authenticationService.login(this.f.email.value, this.f.password.value);
              this.btnLock = 3;
              setTimeout(()=> {
                this.router.navigate(['/inicio']);
              }, 1000);
          },
          error => {
            this.submitted = false;
            this.btnLock = 1;
            this.error = error ? error : '';
          });
    }
  }

  resolved(captchaResponse: string) {
    this.captchaIsTrue = true;
  }

}

