<div class="p-2" *ngIf="seccion === 'home'">
  <div>
    <h4 class="mb-0">Casos recientes</h4>
    <br>
  </div>
  <app-scheleton *ngIf="load"></app-scheleton>
  <div *ngIf="!load">
  <div *ngFor="let caso of casos" >
  <div class="mt-1 mb-1" (click)="abrirDetalle(caso)">
    <div >
      <div class=" align-items-center justify-content-center">
          <div class="col-md-0">
              <div class="card">
                  <div class="d-flex justify-content-between p-1 px-0">
                      <div class="d-flex flex-row align-items-center"> <img src="https://i.imgur.com/UXdKE3o.jpg" width="50" class="rounded-circle">
                          <div class="d-flex flex-column ml-2"> <span class="font-weight-bold">Tulio Garcia</span> <small class="text-primary">{{caso.createdAt | date: 'dd/MM/yyyy hh:mm'}}</small> </div>
                      </div>
                      <div class="d-flex flex-row mt-1 ellipsis"> <small class="mr-2"></small> </div>
                  </div><b>{{caso.informacion_apertura ? caso.informacion_apertura.motivo : ''}}</b>
                  <div class="p-0">
                      <p>Estatus: {{caso.estatus}}, Area: {{caso.consulta_desc}}</p>
                      <p class="text-justify"><br>{{caso.informacion_apertura ? caso.informacion_apertura.descripcion_general : '' }}</p>
                      <hr>
                      <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex flex-row icons d-flex align-items-center"> <i class="fa fa-heart"></i>  <i class="fa fa-smile-o ml-2"></i> </div>
                          <div class="d-flex flex-row muted-color"> <span style="margin-right: 10px;">{{caso.comentarios}} comentarios</span> <span class="ml-2">Ver detalle </span> </div>
                      </div>
                      <hr>
                  </div>
              </div> 
          </div>
      </div>
  </div>
  </div>
</div>
</div>
</div>
<ng-container #practica></ng-container>
