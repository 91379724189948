import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-preguntasrapidas',
  templateUrl: './preguntasrapidas.component.html',
  styleUrls: ['./preguntasrapidas.component.scss']
})

/**
 * Tabs-group component
 */
export class PreguntasrapidasComponent implements OnInit {

  public isCollapsed: boolean;
  groups: any[];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.groups = [
      {
          id: 1, name: 'chat.tabs.groups.list.general'
      }, {
          id: 2, name: 'chat.tabs.groups.list.reporting',
          unread: '+23'
      }, {
          id: 3, name: 'chat.tabs.groups.list.designers'
      }, {
          id: 4, name: 'chat.tabs.groups.list.developer',
          unread: 'New'
      }, {
          id: 5, name: 'chat.tabs.groups.list.projectalpha'
      }, {
          id: 6, name: 'chat.tabs.groups.list.snacks',
      }
  ];

    // collpsed value
    this.isCollapsed = true;
  }

  /**
   * Open add group modal
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openGroupModal(content: any) {
    this.modalService.open(content, { centered: true });
  }
}
