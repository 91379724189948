import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http'; // <----
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialog, MatDialogModule, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PublicService } from 'src/app/public.service';
@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.scss']
})
/**
 * Tabs-Profile component
 */
export class AperturaComponent implements OnInit {
  @Output() abrir: EventEmitter<string> = new EventEmitter();
  @Output() cerrarDetalle: EventEmitter<string> = new EventEmitter();
  seccion = 'home';
  motivo: any = '';
  descripcion: any = '';
  evidencias: any = '';
  etapa: any = '';
  involucrados: any = '';
  contraparte: any = '';
  demandas: any = '';
  urlTipoCaso: any = '';
  tipoCaso: any;
  ciudad: any;
  checklist: FormGroup;
  @Input() apertura: string;
  error;
  myControl = new FormControl();
  options: any[] = [{ name: 'CDMX' }, { name: 'NUEVO LEON' }, { name: 'PUEBLA' }, { name: 'JALISCO' }];
  filteredOptions: Observable<any[]>;

  constructor(fb: FormBuilder, private publicService: PublicService, private http: HttpClient, public dialog: MatDialog, private router: Router) {
    this.checklist = fb.group({
      check1: false,
      check2: false,
      check3: false,
      check4: false
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    console.log(this.apertura);
    this.cargarTipoDeCaso();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.options.slice())),
    );
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  cargarTipoDeCaso(): void {
    this.publicService.get_cargar_tipodecaso(this.apertura).subscribe(data => {
      console.log(data);
      this.tipoCaso = data;
    }, error => this.error = error);
  }

  cerrarApertura(): void {
    this.cerrarDetalle.emit('true');
  }

  proceder(): void {
    const dialogRef = this.dialog.open(ConfirmarAperturaDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result === true) {
        let request = {
          "idTipodeCaso": this.apertura,
          "motivo": this.motivo,
          "descripcion": this.descripcion,
          "checklist": JSON.stringify(this.checklist.value),
          "evidencias": this.evidencias,
          "etapa_del_proceso": this.etapa,
          "involucrados": this.involucrados,
          "info_contraparte": this.contraparte,
          "demandas_solicitadas": this.demandas
        };
        this.publicService.post_abrir_consulta(request).subscribe(data => {
          console.log(data);
          let request2 = {
            "titulo": "Despido injustificado",
            "estatus": "Apertura",
            "presupuesto": +this.tipoCaso.precio,
            "tipo_de_caso": this.tipoCaso.id,
            "ciudad": this.ciudad.name,
            "expedientes": "Ninguno",
            "idConsulta": this.tipoCaso.id_practica,
            "comentarios": "El caso ha sido aperturado",
            "consulta_desc": this.tipoCaso.tag
          };
          this.publicService.post_mi_consulta(request2).subscribe(response => {
            console.log(response);
            window.location.reload();
          }, error => console.log(error));
        }, error => console.log(error));
      }
    });

  }

}

@Component({
  selector: 'confirmar-apertura',
  template: `<h2 mat-dialog-title>¿Desea aperturar este caso?</h2>
  <mat-dialog-content class="mat-typography">
    <h3>Una vez aperturado el caso sera dirigido al inicio, podra darle seguimiento en la sección mis consultas</h3>
    <p>Los abogados de inmediato revisaran su caso y haran comentarios preliminares, a fin de que pueda elegir con quien trabajar correctamente.</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Iniciar</button>
  </mat-dialog-actions>`,
})
export class ConfirmarAperturaDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

export interface DialogData {}
