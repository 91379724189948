import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PracticaComponent } from './practica/practica.component';
import { HttpClient } from '@angular/common/http'; // <----
import { PublicService } from 'src/app/public.service';

@Component({
  selector: 'app-wall',
  templateUrl: './wall.component.html',
  styleUrls: ['./wall.component.scss']
})

export class WallComponent implements OnInit {

  seccion = 'home';
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  practicaId = 'fiscal';
  private _ref;
  getParam;
  components = [];
  partnerInfo;
  error;
  casos;
  load = true;

  constructor(private actRoute: ActivatedRoute, private publicService: PublicService, private factoryResolver: ComponentFactoryResolver, private http: HttpClient) {
    console.log(this.seccion);
    this.getParam = this.actRoute.snapshot.params.idPractica ? this.actRoute.snapshot.params.idPractica : 0;
  }


  abrirDetalle(id): void {
    this.seccion = 'inicio';

    console.log(this.practica);
    console.log(id);

    const factory = this.factoryResolver.resolveComponentFactory(PracticaComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.detalleCaso = id;
    this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
        this.practica.clear();
        this.seccion = 'home';
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.cargarInformacionPartner();
  }

  cargarInformacionPartner(): void {
    this.publicService.get_perfil_lawyers().subscribe(data => {
      console.log(data);
      this.partnerInfo = data;
      let lotes = '';
      let practicas = '';
      for (var i=0; i < this.partnerInfo.profile_lawyer.lotes_secundarios.length; i++) {
        lotes += this.partnerInfo.profile_lawyer.lotes_secundarios[i] + ",";
      }
      for (var i=0; i < this.partnerInfo.profile_lawyer.practicas.length; i++) {
        practicas += this.partnerInfo.profile_lawyer.practicas[i] + ",";
      }
      lotes = lotes + this.partnerInfo.profile_lawyer.lote;
      practicas = practicas.slice(0, -1);
      console.log(lotes);
      console.log(practicas);
      this.publicService.get_wall(lotes, practicas).subscribe(response => {
        this.casos = response.items;
     },error => this.error = error);
      this.load = false;
   },error => this.error = error);
  }


  ngAfterViewInit(): void{
    console.log(this.getParam);
    // setTimeout(function () {
      if (+this.getParam !== 0) {
        this.abrirDetalle('penal');
      }
    // }, 1000);
  }



}
