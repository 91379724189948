<div class="layout-wrapper" style="display: flex !important;">
  <!-- Start User chat -->
  <div class="user-chat w-100" id="chat-room">
    <div style="display: flex !important;">

      <!-- start chat conversation section -->
      <div class="w-100">
        <div class="p-0 p-lg-4 border-bottom">
          <div class="row align-items-center">
            <div class="col-sm-6 col-6">
              <div class="d-flex align-items-center">
                <div class="d-block d-lg-none me-2 ms-0">
                  <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
                    (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h5 class="font-size-12 mb-0 text-truncate"><a href="#"
                      class="text-reset user-profile-show">{{nickname}}</a> <i
                      class="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i></h5>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-6">
              <ul class="list-inline user-chat-nav text-end mb-0">

              <ng-template #callContent>
                <div class="modal-body">
                  <div class="text-center p-4">
                      <div class="avatar-lg mx-auto mb-4">
                          <img src="assets/images/users/avatar-4.jpg" alt="" class="img-thumbnail rounded-circle">
                      </div>

                      <h5 class="text-truncate">Doris Brown1</h5>
                      <p class="text-muted">Start Audio Call</p>

                      <div class="mt-5">
                          <ul class="list-inline mb-1">
                              <li class="list-inline-item px-2 me-2 ms-0">
                                  <button type="button" class="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
                                      <span class="avatar-title bg-transparent font-size-20">
                                          <i class="ri-close-fill"></i>
                                      </span>
                                  </button>
                              </li>
                              <li class="list-inline-item px-2">
                                  <button type="button" class="btn btn-success avatar-sm rounded-circle">
                                      <span class="avatar-title bg-transparent font-size-20">
                                          <i class="ri-phone-fill"></i>
                                      </span>
                                  </button>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              </ng-template>
                <li class="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#videocallModal" (click)="openVideoModal(videoContent)">
                      <i class="ri-vidicon-line"></i>
                  </button>
              </li>
              <ng-template #videoContent>
                <div class="modal-body">
                  <div class="text-center p-4">

                      <p class="text-muted mb-0">Iniciar llamada de Video Chat</p>

                      <div class="mt-5">
                          <ul class="list-inline mb-1">
                              <li class="list-inline-item px-2 me-2 ms-0" (click)="cancelarMeet()">
                                  <button type="button" class="btn btn-danger avatar-sm rounded-circle" data-bs-dismiss="modal">
                                      <span class="avatar-title bg-transparent font-size-20">
                                          <i class="ri-close-fill"></i>
                                      </span>
                                  </button>
                              </li>
                              <li class="list-inline-item px-2" (click)="abrirMeet()">
                                  <button type="button" [disabled]="callBtnD" class="btn btn-success avatar-sm rounded-circle">
                                      <span class="avatar-title bg-transparent font-size-20">
                                          <i class="ri-vidicon-fill"></i>
                                      </span>
                                  </button>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              </ng-template>
                <li class="list-inline-item d-none d-lg-inline-block">
                  <button type="button" class="btn nav-btn user-profile-show" (click)="showUserProfile()">
                    <i class="ri-user-2-line"></i>
                  </button>
                </li>

                <li class="list-inline-item">
                  <div class="dropdown" ngbDropdown>
                    <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <i class="ri-more-fill"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                      <a class="dropdown-item" href="#">{{'chat.chatpanelheader.dropdown.muted'}} <i
                          class="ri-volume-mute-line float-end text-muted"></i></a>
                      <a class="dropdown-item" href="#">{{'chat.chatpanelheader.dropdown.delete'}} <i
                          class="ri-delete-bin-line float-end text-muted"></i></a>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
        <!-- end chat user head -->

        <!-- start chat conversation -->
        <div #scroll id="chatsc" style="height: calc(100vh - 300px); overflow:scroll" class="chat-conversation p-3 p-lg-4">
          <div class="message-box" *ngFor="let chat of chats">
            <div class="chat-status" text-center *ngIf="chat.type==='join'||chat.type==='exit';else message">
              <span class="chat-date">{{chat.date | date:'short'}}</span>
              <span class="chat-content-center">{{chat.message}}</span>
            </div>
            <ng-template #message>
              <div class="chat-message">
                <div class="right-bubble" [ngClass]="{'left-bubble': chat.nickname === nickname, 'right-bubble': chat.nickname !== nickname}">
                  <span class="msg-name" *ngIf="chat.nickname === nickname">Yo</span>
                  <span class="msg-name" *ngIf="chat.nickname !== nickname">{{chat.nickname}}</span>
                  <span class="msg-date" *ngIf="chat.nickname === nickname"> escribrí {{chat.date}}</span>
                  <span class="msg-date" *ngIf="chat.nickname !== nickname"> escribrio {{chat.date}}</span>
                  <span class="msg-txt" [innerHTML]="chat.message"><br></span>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- end chat conversation end -->

        <!-- start chat input section -->
        <form class="message-form" [formGroup]="chatForm" (ngSubmit)="onFormSubmit(chatForm.value)">
        <div class="p-0 p-lg-4 border-top mb-0">
          <div class="row no-gutters">
            <div class="col">
              <div>
                <input matInput type="text" class="form-control form-control-lg bg-light border-light"
                  placeholder="Enter Message..." formControlName="message"
                  [errorStateMatcher]="matcher">
                <mat-error>
                  <span *ngIf="!chatForm.get('message').valid && chatForm.get('message').touched">Enter your message</span>
                </mat-error>
              </div>
            </div>
            <div class="col-auto">
              <div class="chat-input-links ms-md-2">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                      data-toggle="tooltip" data-placement="top" ngbTooltip="Attached File">
                      <i class="ri-attachment-line"></i>
                    </button>
                  </li>
                  <li class="list-inline-item">
                    <button type="submit" [disabled]="!chatForm.valid"
                      class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                      <i class="ri-send-plane-2-fill"></i>
                    </button>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        </form>
        <!-- end chat input section -->
      </div>
      <!-- end chat conversation section -->

    </div>
  </div>
</div>