<div *ngIf="seccion === 'home'">
  <div id="header_c">
    <textarea id="id1" style="display: none">https://esug.mx/legal/fiscal1.jpg</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarApertura()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
              <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
              </svg></span>
            <span class="abrir-consulta">Abrir consulta</span>
          </div>
        </div>
      </div>
      </div>
  </div>
  <!-- Start profile content -->
  <div class="p-0" id="contenedor-main">
    <div class="stepper d-flex flex-column mt-5 ml-2">
      <div class="d-flex mb-1">
        <div class="d-flex flex-column pr-4 align-items-center">
          <div class="rounded-circle bg-black text-white mb-2">1</div>
          <div class="line h-100"></div>
        </div>
        <div>
          <h5 class="text-dark">Información general(Obligatoria)</h5>
          <p class="lead text-muted pb-3">Motivo<br>
            <input type="text" class="css-input" [(ngModel)]="motivo" name="motivo" /></p>
          <p class="lead text-muted pb-3">Descripción general<br>
              <input type="text" class="css-textarea" [(ngModel)]="descripcion" name="descripcion" />
          </p>
        
          <o>
            <section class="example-section" [formGroup]="checklist">
            <div class="ch">
              <mat-checkbox formControlName="check1" id="check1">
              ¿El solicitante tiene antecedentes penales?
            </mat-checkbox>
            </div>
            <div class="ch">
              <mat-checkbox formControlName="check2" id="check2">
                ¿Ha realizado actas o denuncias previas del caso?
              </mat-checkbox>
            </div>
            <div class="ch">
              <mat-checkbox formControlName="check3" id="check3">
                ¿Existe una relación parental entre las <br>partes involucradas?
              </mat-checkbox>
            </div>
            <div class="ch">
              <mat-checkbox formControlName="check4" id="check4">
                ¿El solicitante padece alguna enfermedad <br>mental o consume drogas?
              </mat-checkbox>
            </div>
            </section>
          </o>
        </div>
      </div>
      <div class="d-flex mb-1">
        <div class="d-flex flex-column pr-4 align-items-center">
          <div class="rounded-circle bg-black text-white mb-2">2</div>
          <div class="line h-100"></div>
        </div>
        <div>
          <h5 class="text-dark">El caso(Opcional)</h5>
          <p class="lead text-muted pb-3">Evidencias<br>
            <input type="text" class="css-input" [(ngModel)]="evidencias" name="evidencias" /></p>
          <p class="lead text-muted pb-3">Etapa del proceso<br>
              <input type="text" class="css-textarea" [(ngModel)]="etapa" name="etapa" />
          </p>
          <p class="lead text-muted pb-3">Ciudad del caso}<br>
            <input type="text" matInput [formControl]="myControl" [(ngModel)]="ciudad" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete"  class="css-textarea" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
        </p>
          <p class="lead text-muted pb-3">Involucrados<br>
            <input type="text" class="css-textarea" [(ngModel)]="involucrados" name="involucrados" />
        </p>
        <p class="lead text-muted pb-3">Información de la contraparte<br>
          <input type="text" class="css-textarea" [(ngModel)]="contraparte" name="contraparte" />
      </p>
      <p class="lead text-muted pb-3">Demandas solicitadas<br>
        <input type="text" class="css-textarea" [(ngModel)]="demandas" name="demandas" />
    </p>
          <o>
            <div class="ch1">
            </div>
          </o>
        </div>
      </div>
      <div class="d-flex mb-1">
        <div class="d-flex flex-column pr-4 align-items-center">
          <div class="rounded-circle bg-black text-white mb-2">3</div>
          <div class="line h-100"></div>
        </div>
        <div>
          <h5 class="text-dark">Pago</h5>
          <p class="lead text-muted pb-3">{{tipoCaso.nombre}} - {{tipoCaso.tag}}<br>
          <p class="lead text-muted pb-3">Precio: {{tipoCaso.precio}}$ por consultoría<br>
            </p>
          </div>
      </div>
    </div>
    </div>
    <div class="ctni">
      <div class="myButton" (click)="proceder();">PROCEDER</div>
    </div>
  </div>
