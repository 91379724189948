import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import authgear from '@authgear/web';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';
import { Router } from '@angular/router';
import { PublicService } from 'src/app/public.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    error;
    constructor(private publicService: PublicService, private http: HttpClient, private authenticationService: AuthenticationService, private router: Router, private authFackservice: AuthfakeauthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                authgear
                .refreshAccessTokenIfNeeded()
                .then(() => {
                    // access token is ready to use
                    // accessToken can be string or undefined
                    // it will be empty if user is not logged in or session is invalid
                    const data = {
                      token: authgear.accessToken
                    };
                    console.log(data);
                    localStorage.setItem('currentUser', JSON.stringify(data));
                    window.location.reload();
                });
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }
}
