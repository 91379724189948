<div *ngIf="seccion === 'home'">
  <div id="headerc">
    <textarea id="id1" style="display: none">https://esug.mx/legal/fiscal.jpg</textarea>
    <div class="weather-card one">
      <div class="top">
        <div class="wrapper">
          <div class="mynav">
            <span (click)="cerrarPractica()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left"
                width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000"
                enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g>
                  <path
                    d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z" />
                  <path
                    d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z" />
                  <path
                    d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z" />
                </g>
              </svg></span>
            <a href="javascript:;">
              <svg class="lnr-cog" fill="red" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                viewBox="0 0 24 24">
                <path
                  d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tamano">
    <h1 style="
    color: #FF0000;
    text-align: left;
    font-size: 14px !important;
">{{detalleCaso.informacion_apertura.motivo}}</h1>
    <p topmargin=0 style="color:#717D7E">{{detalleCaso.informacion_apertura.ciudad}} {{detalleCaso.comentarios}},
      comentarios</p>
    <h2 style="color: #050404"> {{detalleCaso.presupuesto}} MXN </h2>
    <h2 style="color:#050404; font-size: 16px !important;">Reseña</h2>
    <p topmargin=0 style="color:#030303" class="cg-title01">{{detalleCaso.informacion_apertura.descripcion_general}}</p>
    <br><br>
    <h2 style="color:#050404; font-size: 16px !important;">Checklist</h2>
    <div class="cg-title01" *ngFor="let check of checkList">
      <svg width="8px" height="8px" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000"
        enable-background="new 0 0 1000 1000" xml:space="preserve">
        <g>
          <path
            d="M990,193.4c-2.9,2-6.2,3.5-8.6,6C762.8,428.9,544.2,658.5,325.6,888.2c-1.1,1.1-1.8,2.5-2.7,3.8c-1.1,0-2.1,0-3.2,0c-1.8-2.5-3.4-5.2-5.5-7.4C215.7,780.8,117,677,18.3,573.3c-2.5-2.6-5.5-4.6-8.3-6.9c0-1.1,0-2.1,0-3.2c27.2-24.8,54.4-49.6,83-75.5c73,76.7,150.5,158.1,230.1,241.6c3.4-4.6,5.9-8.8,9.1-12.2c191.6-201.4,383.3-402.7,575-604.1c1.4-1.5,2.5-3.3,3.8-5c1.1,0,2.1,0,3.2,0c25.3,26.3,50.6,52.7,75.9,79C990,189.1,990,191.2,990,193.4z" />
        </g>
      </svg> {{check.value ? check.value : ''}}<br>
    </div>
    <div *ngFor="let info of info_conf">
      <h2 *ngIf="info.contenido" style="color:#050404; font-size: 16px !important;"><br><br>{{info.titulo}}</h2>
      <div class="cg-title01">
        {{info.contenido}}<br>
      </div>
    </div>
    <h2 style="color:#050404; font-size: 16px !important;"><br><br>Añadir comentario</h2>
    <form id="contactForm" [formGroup]="formGroup" (ngSubmit)="proceder()">
      <label for="name">Comentario</label>
      <textarea type="text" class="css-textarea" formControlName="comentarios" id="desc1" name="desc1"></textarea>
      <button mat-flat-button color="primary" class="myButton">Enviar</button>
    </form>
    <br>
    <br><br>
    <h1 style="color:#050404">Opinion de abogados sobre el caso</h1>
    <br>

    <div *ngFor="let comentario of listComentarios">
      <div style="overflow: auto;">
        <h1 style="color:#FF0000">
          <img src="{{comentario.lawyer.profile_lawyer.logo}}" class="redondo-izquierda">
          <p class="centrado1">{{comentario.lawyer.profile_lawyer.nombre}}<br>
          <p class="centrado1" style="color:#717D7E">{{comentario.lawyer.profile_lawyer.sedes}}, México<br>
          </p>
        </h1>
      </div>
      <div style="color:#000000" class="centrado2"> {{comentario.comentario}}</div>
    </div>
    <br><br><br>
  </div>
</div>
<ng-container #detalle></ng-container>