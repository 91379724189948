import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicService } from 'src/app/public.service';
import { environment } from 'src/environments/environment';
import { SeguimientoComponent } from '../asuntos/seguimiento/seguimiento.component';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
/**
 * Tabs-settings component
 */
export class ProductosComponent implements OnInit {

  seccion = 'home';
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;
  practicaId = 'fiscal';
  private _ref;
  components = [];
  load = true;
  url: string = environment.api + '/private/casoslawyers?search=productos';
  error: any;
  productos: any;

  constructor(private publicService: PublicService, private actRoute: ActivatedRoute, private http: HttpClient, private factoryResolver: ComponentFactoryResolver) 
    { }

  ngOnInit(): void {
    this.load = false;
    this.cargarPractica();
  }

  cargarPractica(): void {
    this.publicService.get_casoslawyer().subscribe(data => {
        console.log(data);
        this.load = false;
        this.productos = data.items;
     },error => this.error = error);
  }

  abrirPractica(id): void {
    console.log(id);
    this.seccion = 'inicio';

    const factory = this.factoryResolver.resolveComponentFactory(SeguimientoComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
        this.practica.clear();
        this.seccion = 'home';
    });
  }

}
