<div *ngIf="seccion === 'home'">
    <div id="header">
      <textarea id="id1" style="display: none">
  https://esug.mx/legal/fiscal.jpg</textarea
      >
      <div class="weather-card one">
        <div class="top">
          <div class="wrapper">
            <div class="mynav">
              <a href="/white" style="cursor: pointer"
                ><svg
                  fill="white"
                  class="lnr-chevron-left"
                  width="15"
                  height="18"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1000 1000"
                  enable-background="new 0 0 1000 1000"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"
                    />
                    <path
                      d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"
                    />
                    <path
                      d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"
                    />
                  </g></svg
              ></a>
              <a href="">
                <svg
                  class="lnr-cog"
                  fill="red"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
                  />
                </svg>
              </a>
            </div>
            <div id="header-contenido">
              <h1 class="heading">Tus productos gestionados</h1>
              <div class="mynav01">
                <div class="lnr-chevron-left01">{{productos.length}}<br />Productos contratados</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bck" style="margin-top: 220px;">
      <div *ngFor="let item of productos" class="p-3" id="contenedor-main">
        <div (click)="abrirPractica(item.consulta.id)" class="profile-card-4 text-center">
          <img
          src="https://esug.mx/legal/divorciohijos.jpg"
          style="width: 100%"
            class="img img-responsive"
          />
          <div class="profile-content">
            <div class="profile-name">
              {{item.informacion_apertura.motivo}}
              <p class="practica">{{item.consulta.estatus}}</p>
            </div>
            <div class="profile-description">
              {{ item.informacion_apertura.descripcion_general }}
            </div>
            <div class="row">
              <div class="col-xs-2">
                <div class="profile-overview">
                  <p>Precio</p>
                  <h4>${{ item.precio }}</h4>
                  <p>Fecha</p>
                  <p>{{ item.createdAt | date: 'dd/MM/yyyy hh:mm' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-container #practica></ng-container>
  