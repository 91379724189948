import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { RoomComponent } from './room/room.component';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {
  seccion = 'home';
  private _ref;
  @ViewChild('practica', { read: ViewContainerRef }) practica: ViewContainerRef;

  constructor(private factoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
  }

  abrirRoom(id): void {
    console.log(id);
    this.seccion = 'inicio';

    const factory = this.factoryResolver.resolveComponentFactory(RoomComponent);
    this._ref = this.practica.createComponent(factory);

    this._ref.instance.practicaId = id;
    this._ref.instance.cerrar.subscribe(data => {
        console.log(data);
        this.practica.clear();
        this.seccion = 'home';
    });
  }

}
