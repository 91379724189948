<div *ngIf="seccion === 'home'">
  <div id="header" >
      <textarea id="id1" style="display: none">https://esug.mx/legal/fiscal.jpg</textarea>
      <div class="weather-card one">
        <div class="top">
          <div class="wrapper">
            <div class="mynav">
              <a href="/white" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
                </svg></a>
              <a href="">
          <svg class="lnr-cog" fill="red" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/></svg>
        </a>
            </div>
            <div id="header-contenido">
            <h1 class="heading">Investigación</h1>
            <div class="mynav01">
              <div class="lnr-chevron-left01">{{reclaims.length}}<br>Consultas realizadas</div>
            </div>
            </div>
          </div>
        </div>
        </div>
    </div> 
<section class="wrapper bck">
<div style="padding-top: 15px;" id="contenedor-main">

  <div class="container">

      <div >
          <div class="col-md-12" *ngFor="let reclaim of reclaims" (click)="abrirPractica(reclaim.consulta.id)">
              <div class="card text-white card-has-bg click-col"
                  style="background-image:url('https://source.unsplash.com/600x900/?tech,street');">
                  <img class="card-img d-none" src="https://source.unsplash.com/600x900/?tech,street"
                      alt="Goverment Lorem Ipsum Sit Amet Consectetur dipisi?">
                  <div class="card-img-overlay d-flex flex-column">
                      <div class="card-body">
                          <small class="card-meta mb-2">Estatus: {{reclaim.estatus}}</small>
                          <h4 style="    font-size: 19px !important;" class="card-title mt-0 "><a class="text-white" herf="#">{{reclaim.informacion_apertura.motivo}}</a></h4>
                          <small><i class="far fa-clock"></i> {{reclaim.createdAt | date: 'dd/MM/yyyy hh:mm' }}</small>
                      </div>
                  </div>
              </div>
          </div>

      </div>

  </div>
  </div>
</section>
</div>
<ng-container #practica></ng-container>
