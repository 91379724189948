<div *ngIf="seccion === 'home'">
<div id="header">
  <textarea id="id1" style="display: none">https://esug.mx/legal/fiscal2.jpg</textarea>
  <div class="weather-card one">
    <div class="top">
      <div class="wrapper">
        <div class="mynav">
          <span (click)="cerrarDetail()" style="cursor: pointer;"><svg fill="white" class="lnr-chevron-left" width="15" height="18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"/><path d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"/><path d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"/></g>
            </svg></span>
        </div>
      </div>
    </div>
    </div>
</div>
<!-- Start profile content -->
<div class="p-3" id="contenedor-main">
  <div class="titulo" (click)="abrirApertura()">Obligaciones y trámites</div>
  <div class="row">
    <div class="column">Precio: 400$</div>
    <div class="column">+ 50 consultas realizadas</div>
  </div>
</div>
<div class="cuadro">
  <div class="tipos"><br>LA PRACTICA<br></div>
  <h5>DESCRIPCIÓN</h5>
  <div class="cg-title">{{detalle.descripcion}}</div>
  <div class="row">
    <div class="column"></div>
    <div class="column rightcl"><a href="#" class="myButton">LEER MÁS</a></div>
  </div>
</div>
<div class="cuadro1">
  <div class="tipos"><br>INFORMACIÓN<br></div>
  <h5>REQUERIDA</h5>
  <div class="cg-title01" *ngFor="let infoRequerida of detalle.informacion_requerida">
    <svg width="8px" height="8px" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
      <g><path d="M990,193.4c-2.9,2-6.2,3.5-8.6,6C762.8,428.9,544.2,658.5,325.6,888.2c-1.1,1.1-1.8,2.5-2.7,3.8c-1.1,0-2.1,0-3.2,0c-1.8-2.5-3.4-5.2-5.5-7.4C215.7,780.8,117,677,18.3,573.3c-2.5-2.6-5.5-4.6-8.3-6.9c0-1.1,0-2.1,0-3.2c27.2-24.8,54.4-49.6,83-75.5c73,76.7,150.5,158.1,230.1,241.6c3.4-4.6,5.9-8.8,9.1-12.2c191.6-201.4,383.3-402.7,575-604.1c1.4-1.5,2.5-3.3,3.8-5c1.1,0,2.1,0,3.2,0c25.3,26.3,50.6,52.7,75.9,79C990,189.1,990,191.2,990,193.4z"/></g>
      </svg> {{infoRequerida}}<br></div>
</div>
<div class="cuadro">
  <div class="tipos"><br>QUÉ ES<br></div>
  <h5>LO QUE INCLUYE LA CONSULTORÍA</h5>
  <div class="cg-title01">
    <svg width="8px" height="8px" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
      <g><path d="M990,193.4c-2.9,2-6.2,3.5-8.6,6C762.8,428.9,544.2,658.5,325.6,888.2c-1.1,1.1-1.8,2.5-2.7,3.8c-1.1,0-2.1,0-3.2,0c-1.8-2.5-3.4-5.2-5.5-7.4C215.7,780.8,117,677,18.3,573.3c-2.5-2.6-5.5-4.6-8.3-6.9c0-1.1,0-2.1,0-3.2c27.2-24.8,54.4-49.6,83-75.5c73,76.7,150.5,158.1,230.1,241.6c3.4-4.6,5.9-8.8,9.1-12.2c191.6-201.4,383.3-402.7,575-604.1c1.4-1.5,2.5-3.3,3.8-5c1.1,0,2.1,0,3.2,0c25.3,26.3,50.6,52.7,75.9,79C990,189.1,990,191.2,990,193.4z"/></g>
      </svg> Atención a dudas sobre el caso<br>
      <svg width="8px" height="8px" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
        <g><path d="M990,193.4c-2.9,2-6.2,3.5-8.6,6C762.8,428.9,544.2,658.5,325.6,888.2c-1.1,1.1-1.8,2.5-2.7,3.8c-1.1,0-2.1,0-3.2,0c-1.8-2.5-3.4-5.2-5.5-7.4C215.7,780.8,117,677,18.3,573.3c-2.5-2.6-5.5-4.6-8.3-6.9c0-1.1,0-2.1,0-3.2c27.2-24.8,54.4-49.6,83-75.5c73,76.7,150.5,158.1,230.1,241.6c3.4-4.6,5.9-8.8,9.1-12.2c191.6-201.4,383.3-402.7,575-604.1c1.4-1.5,2.5-3.3,3.8-5c1.1,0,2.1,0,3.2,0c25.3,26.3,50.6,52.7,75.9,79C990,189.1,990,191.2,990,193.4z"/></g>
        </svg> Visión critica del caso<br>
      <svg width="8px" height="8px" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
        <g><path d="M990,193.4c-2.9,2-6.2,3.5-8.6,6C762.8,428.9,544.2,658.5,325.6,888.2c-1.1,1.1-1.8,2.5-2.7,3.8c-1.1,0-2.1,0-3.2,0c-1.8-2.5-3.4-5.2-5.5-7.4C215.7,780.8,117,677,18.3,573.3c-2.5-2.6-5.5-4.6-8.3-6.9c0-1.1,0-2.1,0-3.2c27.2-24.8,54.4-49.6,83-75.5c73,76.7,150.5,158.1,230.1,241.6c3.4-4.6,5.9-8.8,9.1-12.2c191.6-201.4,383.3-402.7,575-604.1c1.4-1.5,2.5-3.3,3.8-5c1.1,0,2.1,0,3.2,0c25.3,26.3,50.6,52.7,75.9,79C990,189.1,990,191.2,990,193.4z"/></g>
        </svg> Opción de seguimiento constante desde la aplicación<br>
        </div>
</div>
<div class="cuadro1">
  <div class="tipos"><br>QUÉ ES<br></div>
  <h5>LO QUE INCLUYE NO INCLUYE</h5>
  <div class="cg-title01">
    <svg width="8px" height="8px" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
      <g><path d="M990,193.4c-2.9,2-6.2,3.5-8.6,6C762.8,428.9,544.2,658.5,325.6,888.2c-1.1,1.1-1.8,2.5-2.7,3.8c-1.1,0-2.1,0-3.2,0c-1.8-2.5-3.4-5.2-5.5-7.4C215.7,780.8,117,677,18.3,573.3c-2.5-2.6-5.5-4.6-8.3-6.9c0-1.1,0-2.1,0-3.2c27.2-24.8,54.4-49.6,83-75.5c73,76.7,150.5,158.1,230.1,241.6c3.4-4.6,5.9-8.8,9.1-12.2c191.6-201.4,383.3-402.7,575-604.1c1.4-1.5,2.5-3.3,3.8-5c1.1,0,2.1,0,3.2,0c25.3,26.3,50.6,52.7,75.9,79C990,189.1,990,191.2,990,193.4z"/></g>
      </svg> Traḿites y gestiones ante autoridades(costo adicional)<br>
      <svg width="8px" height="8px" style="margin-right: 10px;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
        <g><path d="M990,193.4c-2.9,2-6.2,3.5-8.6,6C762.8,428.9,544.2,658.5,325.6,888.2c-1.1,1.1-1.8,2.5-2.7,3.8c-1.1,0-2.1,0-3.2,0c-1.8-2.5-3.4-5.2-5.5-7.4C215.7,780.8,117,677,18.3,573.3c-2.5-2.6-5.5-4.6-8.3-6.9c0-1.1,0-2.1,0-3.2c27.2-24.8,54.4-49.6,83-75.5c73,76.7,150.5,158.1,230.1,241.6c3.4-4.6,5.9-8.8,9.1-12.2c191.6-201.4,383.3-402.7,575-604.1c1.4-1.5,2.5-3.3,3.8-5c1.1,0,2.1,0,3.2,0c25.3,26.3,50.6,52.7,75.9,79C990,189.1,990,191.2,990,193.4z"/></g>
        </svg> Diligencias(costo adicional)<br>
        </div>
</div>
<div class="cuadro">
  <div class="tipos"><br>ETAPAS DEL PROCEESO<br></div>
  <h5>PENAL</h5>
  <div class="cg-title01">
    <button type="button" class="btn btn-process">ETAPA 1 - INVESTIGACIÓN INICIAL <svg widht="7px" height="7px" style="float: right;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
    
          .cls-2 {
            fill: #1d1d1d;
          }
        </style>
      </defs>
      <g id="Group_1" data-name="Group 1" transform="translate(274 265) rotate(180)">
        <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(253 244)"/>
        <g id="angle-left" transform="translate(366.54 264.5) rotate(180)">
          <path id="Path_1" data-name="Path 1" class="cls-2" d="M108.129,10.99l-8.6,8.6a1.4,1.4,0,1,1-1.981-1.98l7.61-7.61L97.549,2.391A1.4,1.4,0,0,1,99.53.41l8.6,8.6a1.4,1.4,0,0,1,0,1.98Z"/>
        </g>
      </g>
    </svg></button><br>
    <button type="button" class="btn btn-process">ETAPA 2 - INVESTIGACIÓN COMPLEMENTARIA <svg widht="7px" height="7px" style="float: right;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
    
          .cls-2 {
            fill: #1d1d1d;
          }
        </style>
      </defs>
      <g id="Group_1" data-name="Group 1" transform="translate(274 265) rotate(180)">
        <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(253 244)"/>
        <g id="angle-left" transform="translate(366.54 264.5) rotate(180)">
          <path id="Path_1" data-name="Path 1" class="cls-2" d="M108.129,10.99l-8.6,8.6a1.4,1.4,0,1,1-1.981-1.98l7.61-7.61L97.549,2.391A1.4,1.4,0,0,1,99.53.41l8.6,8.6a1.4,1.4,0,0,1,0,1.98Z"/>
        </g>
      </g>
    </svg></button>
    <button type="button" class="btn btn-process">ETAPA 3 - ETAPA INTERMEDIA <svg widht="7px" height="7px" style="float: right;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
    
          .cls-2 {
            fill: #1d1d1d;
          }
        </style>
      </defs>
      <g id="Group_1" data-name="Group 1" transform="translate(274 265) rotate(180)">
        <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(253 244)"/>
        <g id="angle-left" transform="translate(366.54 264.5) rotate(180)">
          <path id="Path_1" data-name="Path 1" class="cls-2" d="M108.129,10.99l-8.6,8.6a1.4,1.4,0,1,1-1.981-1.98l7.61-7.61L97.549,2.391A1.4,1.4,0,0,1,99.53.41l8.6,8.6a1.4,1.4,0,0,1,0,1.98Z"/>
        </g>
      </g>
    </svg></button>
    <button type="button" class="btn btn-process">ETAPA 4 - JUICIO ORAL <svg widht="7px" height="7px" style="float: right;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
    
          .cls-2 {
            fill: #1d1d1d;
          }
        </style>
      </defs>
      <g id="Group_1" data-name="Group 1" transform="translate(274 265) rotate(180)">
        <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(253 244)"/>
        <g id="angle-left" transform="translate(366.54 264.5) rotate(180)">
          <path id="Path_1" data-name="Path 1" class="cls-2" d="M108.129,10.99l-8.6,8.6a1.4,1.4,0,1,1-1.981-1.98l7.61-7.61L97.549,2.391A1.4,1.4,0,0,1,99.53.41l8.6,8.6a1.4,1.4,0,0,1,0,1.98Z"/>
        </g>
      </g>
    </svg></button>
    <button type="button" class="btn btn-process">ETAPA 5 - EJECUCIÓN Y SANCIÓN <svg widht="7px" height="7px" style="float: right;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
    
          .cls-2 {
            fill: #1d1d1d;
          }
        </style>
      </defs>
      <g id="Group_1" data-name="Group 1" transform="translate(274 265) rotate(180)">
        <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(253 244)"/>
        <g id="angle-left" transform="translate(366.54 264.5) rotate(180)">
          <path id="Path_1" data-name="Path 1" class="cls-2" d="M108.129,10.99l-8.6,8.6a1.4,1.4,0,1,1-1.981-1.98l7.61-7.61L97.549,2.391A1.4,1.4,0,0,1,99.53.41l8.6,8.6a1.4,1.4,0,0,1,0,1.98Z"/>
        </g>
      </g>
    </svg></button>
  </div>
  <span (click)="abrirApertura(detalle.id)" class="myButton2">SOLICITAR CONSULTORÍA AHORA</span>
<div class="ccenter">o</div> 
<a href="#" class="myButton1">HACER UNA CONSULTA RAPIDA </a>

</div>
<div class="tipos"><br>DESPACHOS &<br></div>
<h5>ABOGADOS</h5>  
<owl-carousel-o [options]="customOptions">
  <ng-template  carouselSlide [width]="ancho" *ngFor="let descriprequerida of lawyers">
    <div class="item" >
    <section >
      <div class="col-xs-12 col-sm-12">
        <div class="row">
          <div class="column">              
            <div class="card" style="width: 176px;">
              <img class="centradoi" src="{{descriprequerida.logo}}" img>
              <h3>{{descriprequerida.nombre}} </h3>
              <p style = "color:#74838587">{{descriprequerida.sedes}} </p>
              <div class="star-rating">
                <a href="#">&#9733;</a>
                <a href="#">&#9733;</a>
                <a href="#">&#9733;</a>
                <a href="#">&#9733;</a>
                <a href="#">&#9733;</a>
              </div>
              <p style = "color:#74838587"> {{descriprequerida.descripcion}} </p>
            </div>   
          </div>
        </div>
      </div>
  </section>
  </div>
  </ng-template>
</owl-carousel-o>
<div>
  <div class="tipos"><br>ASESORIAS<br></div>
<h5>SIMILARES</h5>  
  <owl-carousel-o [options]="customOptions" >
    <ng-template  carouselSlide [width]="ancho" *ngFor="let infotendencia of tendencias">
      <div >      
      <div class="container2" >
        <div class="card3" style="width: 176px">
          <div class="card-head3">
          <div class="box">
          <img src="{{infotendencia.imagen}}" alt="legal">
        </div>
           <div class="button">
             <a href="#" class="texto-encima3 tamaño-icono" style="color:#ff0000ef">❤</a>                                  </div>      
            <div class="product-detail">
              <span> <b>
                <span>
            </span></b></span></div><b>
            <span class="badge">
                       {{infotendencia.nombre}}
                      </span>                  
          </b></div><b>
          <div class="card-body" style="width: 300px">
            <div class="product-desc" >
              <span class="product-title" style="color:#00a000">
                      Precio </span><span class="product-caption">
                        {{infotendencia.precio}}$MXN
                    </span> <br><span class="product-client">Solicitar asesoria <span><a> →</a><a>              
            </a></span></span></div><a>            
          </a></div><a>
        </a></b></div><b><a>
      </a></b></div><b><a>
      </a></b></div>      
    </ng-template>                 
                 
  </owl-carousel-o>
</div>
</div>
<ng-container #apertura></ng-container>